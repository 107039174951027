import { Autocomplete, Button, Container, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { getDiaryEntriesByTag, getTagsMap } from 'src/services/firestore-storage';
import DiaryItems from '../diary/DiaryItems';
import { useTranslation } from 'react-i18next';
import { useUser } from 'src/components/loader/user/UserLoader';
import CustomCalendar from 'src/components/datetime/CustomCalendar';
import { addDays, calculateDateRange, getCurrentDate, toDateTimeFormat } from 'src/utils/datetime-utils';
import { isUserPremium } from 'src/services/auth-service';

// TODO set context as favourites
function SearchPage() {
  const { t } = useTranslation();
  const [tags, setTags] = useState([]);
  const { user } = useUser();

  const [startDate, setStartDate] = useState(addDays(getCurrentDate(), -365));
  const [endDate, setEndDate] = useState(addDays(getCurrentDate(), 1));

  const [selectedTag, setSelectedTag] = useState('');
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(false);

  const [items, setItems] = useState([]);

  useEffect(() => {
    async function load() {
      const tagsEntry = await getTagsMap(user.id);

      const tagsMap = tagsEntry?.tagsMap;
      setTags((tagsMap && Object.keys(tagsMap)) ?? []);
    }

    load();
  }, []);

  async function search() {
    if (!selectedTag) {
      return;
    }

    const items = await getDiaryEntriesByTag(
      user.id,
      selectedTag,
      null,
      toDateTimeFormat(startDate),
      toDateTimeFormat(endDate));

    setItems(items);

    if (items.length > 0) {
      setLoadMoreEnabled(true);
    }
  }

  async function loadMore() {
    if (!selectedTag || !items || items.length === 0) {
      return;
    }

    const newItems = await getDiaryEntriesByTag(
      user.id,
      selectedTag,
      items[items.length - 1].dateTime,
      toDateTimeFormat(startDate),
      toDateTimeFormat(endDate)
    );

    if (newItems.length === 0) {
      setLoadMoreEnabled(false);
      return;
    }

    setItems(items.concat(newItems));
  }

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6}>

          <CustomCalendar
            label={t('startDateLabel')}
            date={startDate}
            updateDate={(newValue) => {
              setStartDate(newValue);
            }}
            disabled={!isUserPremium(user)} 
            todayButton={isUserPremium(user)}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CustomCalendar
            label={t('endDateLabel')}
            date={endDate}
            updateDate={(newValue) => {
              setEndDate(newValue);
            }}
            disabled={!isUserPremium(user)} 
            todayButton={isUserPremium(user)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={4}>
          <Autocomplete
            value={selectedTag}
            onChange={(event, newValue) => {
              if (!newValue) {
                return;
              }
              setSelectedTag(newValue);
            }}
            options={tags}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label={t('selectTag')} />}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Button type="submit" variant="contained" onClick={() => search()} sx={{ mt: '5px' }}>
            {t('search')}
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: '10px' }}>
        <Grid item xs={12} sm={8}>
          <DiaryItems items={items} load={search} />
          {loadMoreEnabled && <Button onClick={() => loadMore()}>{t('loadMore')}</Button>}
        </Grid>
      </Grid>
    </>
  );
}

export default SearchPage;
