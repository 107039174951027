import React, { useState, useEffect } from 'react';
import { setUser } from 'src/services/auth-service';
import { Button, Grid, Link, Paper, Stack, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getUserProfile, upsertUserProfile } from 'src/services/firestore-storage';
import { fromDateTimeFormat, toDateFormat } from 'src/utils/datetime-utils';
import PersonIcon from '@mui/icons-material/Person';
import { useUser } from 'src/components/loader/user/UserLoader';

function ProfilePage() {
  const { t } = useTranslation();
  const { user } = useUser();
  const [profileUser, setProfileUser] = useState(undefined);

  useEffect(() => {
    async function fetchUserData() {
      if (!profileUser) {
        setProfileUser(user);
      }
    }
    fetchUserData();
  }, []);

  return (
    <Paper elevation={1} sx={{ p: 2, mb: 1, maxWidth: "800px" }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center' }}>
          <PersonIcon sx={{ fontSize: 200, color: "#5280eb" }} />
        </Grid>

        <Grid item xs={8}>

          <Stack>
            <>
              <Typography>
                {t('profile.email')}: {profileUser?.email}
              </Typography>
              <Typography>
                {t('profile.role')}: {profileUser?.role}
              </Typography>

              <br />

              {profileUser?.subscriptionStartDate && <Typography>
                {t('profile.subscriptionStartDate')}: {toDateFormat(fromDateTimeFormat(profileUser.subscriptionStartDate))}
              </Typography>}
              {profileUser?.subscriptionEndDate && <Typography>
                {t('profile.subscriptionEndDate')}: {toDateFormat(fromDateTimeFormat(profileUser.subscriptionEndDate))}
              </Typography>}
              <br />

              <Grid container alignItems="center" spacing={1}>
                <Grid item style={{ minWidth: '200px' }}>
                  {t('emailNotificationEnabled')}
                </Grid>
                <Grid item>
                  <Switch
                    checked={profileUser?.emailNotifications?.enabled}
                    onChange={async () => {
                      if (!profileUser) {
                        console.log('user is empty');
                        return;
                      }

                      const userProfile = await getUserProfile(profileUser.id);
                      userProfile.emailNotifications.enabled = !userProfile.emailNotifications.enabled;

                      await upsertUserProfile(userProfile.id, userProfile);
                      setUser(userProfile);
                      setProfileUser(userProfile);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </Grid>
              </Grid>

              {profileUser?.telegramChatId ? (
                <Grid container alignItems="center" spacing={1}>
                  <Grid item style={{ minWidth: '200px' }}>
                    {t('telegramNotificationEnabled')}
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={profileUser?.telegramNotifications?.enabled}
                      onChange={async () => {
                        if (!profileUser) {
                          console.log('user is empty');
                          return;
                        }

                        const userProfile = await getUserProfile(profileUser.id);
                        userProfile.telegramNotifications.enabled = !userProfile.telegramNotifications.enabled;

                        await upsertUserProfile(userProfile.id, userProfile);

                        setProfileUser(userProfile);
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </Grid>
                  <Grid item>
                    <Link title={t('profile.mistakenlyDroppedChat')} href={`https://t.me/${process.env.REACT_APP_TELEGRAM_BOT_NAME}?start=${profileUser?.linkToken}`}>
                      {t('profile.reconnectAgain')}
                    </Link>
                  </Grid>
                </Grid>
              ) : (
                <Link href={`https://t.me/${process.env.REACT_APP_TELEGRAM_BOT_NAME}?start=${profileUser?.linkToken}`}>
                  <Button variant="contained">{t('profile.connectTelegram')}</Button>
                </Link>
              )}
            </>
          </Stack>
        </Grid>
      </Grid >
    </Paper >
  );
}

export default ProfilePage;
