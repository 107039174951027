import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function CommonChart({
    chartName,
    chartData,
    lines,
}) {
    return (
        <div style={{ height: '400px' }}> {/* this is very important, otherwise ResponsiveContainer will be growing horizontally infinitely */}
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    width={500}
                    height={300}
                    data={chartData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <text x="250" y="20" textAnchor="middle" dominantBaseline="middle" fontSize="16px">{chartName}</text>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis domain={[0, 10]} tickCount={10} tick={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]} />
                    <Tooltip />
                    <Legend />
                    {lines.map(l => <Line key={l.name} type="monotone" dataKey={l.name} stroke={l.color} />)}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

export default CommonChart;