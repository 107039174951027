import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getDiaryEntryById } from 'src/services/firestore-storage';
import { fromDateTimeFormat, getDateTimeFromQueryOrNow } from 'src/utils/datetime-utils';
import MedicationForm from './MedicationForm';
import { useEditEntry } from 'src/utils/diary-form-utils';
import { useTranslation } from 'react-i18next';
import { useUser } from 'src/components/loader/user/UserLoader';

function EditMedication() {
  const { t } = useTranslation();
  const { user } = useUser();

  const { id } = useParams();
  const date = getDateTimeFromQueryOrNow();

  const [oldTags, setOldTags] = useState([]);

  const [defaultValues, setDefaultValues] = useState({
    entryType: 'medication',
    medications: [],
    dateTime: fromDateTimeFormat(date),
    hoursDuration: 0,
    minutesDuration: 5,
    notes: '',
    tags: []
  });

  useEffect(() => {
    async function load() {
      const entry = await getDiaryEntryById(user.id, id);

      setOldTags(entry.tags);

      setDefaultValues({
        entryType: 'medication',
        medications: entry.medications,
        dateTime: fromDateTimeFormat(entry.dateTime),
        hoursDuration: entry.hoursDuration,
        minutesDuration: entry.minutesDuration,
        notes: entry.notes,
        tags: entry.tags
      });
    }

    load();
  }, [id, date]);

  const onSubmit = useEditEntry(id, oldTags);

  return (
    <MedicationForm
      title={t('medication.editMedication')}
      submitText={t('edit')}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
    />
  );
}

export default EditMedication;
