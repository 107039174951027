import { Button, Paper } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { getCurrentDate } from 'src/utils/datetime-utils';
import { useTranslation } from 'react-i18next';

function CustomTimePicker({ label, time, updateTime }) {
  const { t } = useTranslation();

  return (
    <Paper>
      <TimePicker
        views={['hours', 'minutes']}
        label={label}
        value={time}
        onChange={(newValue) => updateTime(newValue)}
        ampm={false}
        sx={{
          width: '100% ',
          justifyContent: 'space-around !important',
          '& .MuiOutlinedInput-root': {
            borderWidth: '10px',
            '& fieldset': {
              border: 'none'
            }
          }
        }}
      />
      <Button sx={{ height: '100%' }} onClick={() => updateTime(getCurrentDate())} type="button">
        {t('now')}
      </Button>
    </Paper>
  );
}

export default CustomTimePicker;