import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDiaryEntriesForDate } from 'src/services/firestore-storage';
import { Button, Grid } from '@mui/material';
import { getCurrentDate, toDateFormat, toDateTimeFormat } from 'src/utils/datetime-utils';
import CustomCalendar from 'src/components/datetime/CustomCalendar';
import DiaryItems from './DiaryItems';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { getMockedDiaryItems } from 'src/services/mocked.diary-items';
import { useUser } from 'src/components/loader/user/UserLoader';
import PremiumButton from 'src/components/PremiumButton';

function DiaryPage() {
  const { user } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [diaryDate, setDiaryDate] = useState(getCurrentDate());
  const [items, setItems] = useState([]);

  // used for "React Hook useEffect has a missing dependency: 'load'"
  const featchItems = useCallback(async () => {
    const objForDate = await getDiaryEntriesForDate(user?.id, toDateFormat(diaryDate));

    let items = objForDate;

    if (user.isNewAccount && items.length === 0) {
      items = getMockedDiaryItems(i18next.language);
    }

    items.sort((a, b) => Date.parse(b.dateTime) - Date.parse(a.dateTime));
    setItems(items);
  }, [diaryDate, i18next.language]);

  useEffect(() => {
    featchItems();
  }, [featchItems]);

  return (
    <>
      <Grid container spacing={1} sx={{ mt: '8px' }}>
        <Grid item xs={12} sm={4} lg={3}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <CustomCalendar date={diaryDate} updateDate={setDiaryDate} />
            </Grid>

            <Grid item xs={12}>
              <Button
                color="success"
                onClick={() => navigate(`/diary/create-pain?date=${toDateTimeFormat(diaryDate)}`)}
                type="submit"
                variant="contained"
                fullWidth>
                <b>{t('pain.addPain')}</b>
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                color="success"
                onClick={() =>
                  navigate(`/diary/create-medication?date=${toDateTimeFormat(diaryDate)}`)
                }
                type="submit"
                variant="contained"
                fullWidth>
                <b>{t('medication.addMedication')}</b>
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                color="success"
                onClick={() =>
                  navigate(`/diary/create-symptom?date=${toDateTimeFormat(diaryDate)}`)
                }
                type="submit"
                variant="contained"
                fullWidth>
                <b>{t('symptom.addSymptom')}</b>
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                color="success"
                onClick={() =>
                  navigate(`/diary/create-bowelMovement?date=${toDateTimeFormat(diaryDate)}`)
                }
                type="submit"
                variant="contained"
                fullWidth>
                <b>{t('bowelMovement.addBowelMovement')}</b>
              </Button>
            </Grid>

            <Grid item xs={12}>
              <PremiumButton
                label={t('weight.addWeight')}
                onClick={() => navigate(`/diary/create-weight?date=${toDateTimeFormat(diaryDate)}`)} />
            </Grid>

            <Grid item xs={12}>
              <PremiumButton
                label={t('activity.addActivity')}
                onClick={() => navigate(`/diary/create-activity?date=${toDateTimeFormat(diaryDate)}`)} />
            </Grid>

            <Grid item xs={12}>
              <PremiumButton
                label={t('food.addFood')}
                onClick={() => navigate(`/diary/create-food?date=${toDateTimeFormat(diaryDate)}`)} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={8} lg={9}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <DiaryItems items={items} load={featchItems}></DiaryItems>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default DiaryPage;
