import React, { useState, useRef } from 'react';
import { Button, Grid } from '@mui/material';
import { upsertUserProfile } from 'src/services/firestore-storage';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { predictDisease as predictDiseases } from 'src/services/api';
import AiPopUp from './AiPopUp';
import { delay } from 'src/utils/common-utils';
import CircularLoader from 'src/components/CircularLoader';
import { json2csv } from 'json-2-csv';
import { useUser } from 'src/components/loader/user/UserLoader';
import PremiumButton from 'src/components/PremiumButton';
import { pdf } from '@react-pdf/renderer';
import { useTheme } from '@mui/material/styles';
import SymptomsPdfItems from './SymptomsPdfItems';

function ActionButtonsPane({
    items = []
}) {
    const { t } = useTranslation();
    const { user } = useUser();
    const theme = useTheme();

    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [diseases, setDiseases] = useState([]);

    async function analyzeWithAi() {
        const increaseUserAiUsage = async () => {
            user.aiAnalysisCount ??= 0;
            user.aiAnalysisCount++;

            await upsertUserProfile(user.id, user);
        }

        try {
            setIsLoading(true);

            await increaseUserAiUsage();
            await delay(1 * 1000);

            const requestItems = items.map(item => item.symptoms ? item.symptoms[0] : item.pains[0]);
            const diseases = await predictDiseases(requestItems, i18next.language);

            if (!diseases || diseases.length === 0) {
                alert(t('aiWrongLanguage'));
                return;
            }

            setDiseases(diseases);
            setOpen(true);
        } finally {
            setIsLoading(false);
        }
    }

    const downloadAsPdf = async () => {
        const preparedPdf = pdf(<SymptomsPdfItems items={items} theme={theme}/>);
        const blob = await preparedPdf.toBlob()

        downloadBlob(blob, 'diary-items.pdf');
    }

    const downloadAsJson = () => {
        const blob = new Blob([JSON.stringify(items, null, 2)]);

        downloadBlob(blob, 'diary-items.txt');
    };

    const downloadAsCsv = () => {
        const cvsData = items.map((item) => {
            const csvItem = {};

            csvItem[t('common.time')] = item.dateTime;
            csvItem[t('symptom.symptom')] = item.symptoms ? item.symptoms[0] : item.pains[0];
            csvItem[t('severity')] = item.severity;
            csvItem[t('notes')] = item.notes;

            return csvItem;
        });

        const csv = json2csv(cvsData);
        const blob = new Blob([csv]);

        downloadBlob(blob, 'diary-items.csv');
    };

    const downloadBlob = (blob, fileName) => {
        const fileDownloadUrl = URL.createObjectURL(blob);

        const element = document.createElement('a');
        element.href = fileDownloadUrl;
        element.download = fileName;

        element.click();

        // using timeout so that it could be successfully downloaded
        setTimeout(() => {
            document.body.appendChild(element);
            URL.revokeObjectURL(fileDownloadUrl);
        }, 5 * 1000);
    }

    return (
        <>
            {isLoading && <CircularLoader loadingState={'Loading diseases knowldge base...'}/>}

            <AiPopUp
                diseases={diseases}
                open={open}
                handleClick={() => setOpen(false)}
            />

            <Grid container spacing={2} sx={{ mt: '5px' }}>
                <Grid item xs={12} sm={9} container justifyContent="flex-start" spacing={2}>

                    <Grid item xs={12} sm={3}>
                        <Button onClick={() => downloadAsCsv()} type="submit" variant="contained" fullWidth >
                            {t('exportInCsvLabel')}
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <PremiumButton
                            label={t('exportInJsonLabel')}
                            onClick={() => downloadAsJson()} />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Grid item xs={12}>
                            <PremiumButton
                                label={t('exportInPdfLabel')}
                                onClick={downloadAsPdf} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={3} container justifyContent="flex-end">
                    <PremiumButton
                        label={t('analyzeWithAi')}
                        onClick={analyzeWithAi} />
                </Grid>
            </Grid>
        </>
    )
}



export default ActionButtonsPane;