import { useEffect, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, Select } from '@mui/material';
import CommonChart from './CommonChart';

function ActivitiesChart({ inputAggregated }) {
  const { t } = useTranslation();

  const [activityCountName, setActivityCountName] = useState('');
  const [chartData, setChartData] = useState([]);
  const [activityNames, setActivityNames] = useState([]);
  const [chartActivityName, setChartActivityName] = useState('');

  useEffect(() => {
    const activitiesMap = inputAggregated?.loadedActivities?.activitiesMap;
    const datesRange = inputAggregated?.datesRange;

    const names = activitiesMap ? Object.keys(activitiesMap) : []

    let activityName;

    if (names.includes(chartActivityName)) {
      activityName = chartActivityName;
    }

    if (!chartActivityName) {
      activityName = names[0] ?? '';
    }

    if (!activityName || !activitiesMap || !datesRange) {
      setActivityNames([]);
      setChartActivityName('');
      setChartData([]);
      setActivityCountName('');
      return;
    }

    const activityCountChartObj = {};

    for (let date of datesRange) {
      activityCountChartObj[activityName] ??= [];

      const activitiesOnDate = activitiesMap[activityName][date];

      let activityCount = 0;

      if (activitiesOnDate) {
        activityCount = activitiesOnDate.length;
      }

      activityCountChartObj[activityName].push(activityCount);
    }

    const chartData = [];
    const activityCountName = `${activityName} ${t('count')}`;

    for (let i = 0; i < datesRange.length; i++) {
      let item = {
        name: datesRange[i]
      };

      item[activityCountName] = activityCountChartObj[activityName][i];

      chartData.push(item);
    }

    setActivityNames(names);
    setChartActivityName(activityName);
    setActivityCountName(activityCountName);
    setChartData(chartData);
  }, [inputAggregated, chartActivityName]);

  return (
    <>
      {activityNames && activityNames.length > 0 && (
        <Select
          labelId="activity-selector"
          id="activity-selector"
          value={chartActivityName}
          onChange={(e) => { setChartActivityName(e.target.value) }}>
          {activityNames.map(p => <MenuItem key={p} value={p}>{p}</MenuItem>)}
        </Select>
      )}
      <Box sx={{ml: -7}}>
        <CommonChart
          chartName={t('activityChart')}
          chartData={chartData}
          lines={[{ name: activityCountName, color: "#82ca9d" }]} />
      </Box>
    </>
  );
}

export default ActivitiesChart;
