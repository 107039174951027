import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Grid, TextField, Slider } from '@mui/material';
import { toDateFormat, toDateTimeFormat } from 'src/utils/datetime-utils';
import CommonDiaryEntryForm from '../CommonDiaryEntryForm';
import { generateGuid } from 'src/utils/common-utils';
import FavouritesTagsInput from 'src/components/input/FavouritesTagsInput';
import { useTranslation } from 'react-i18next';

const bristolMarks = [1, 2, 3, 4, 5, 6, 7];

function BowelMovementForm({ title, submitText, onSubmit, defaultValues }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [bristolMark, setBristolMark] = useState(defaultValues.bristolMark);
  const [dateTime, setDateTime] = useState(defaultValues.dateTime);
  const [notes, setNotes] = useState(defaultValues.notes);
  const [tags, setTags] = useState(defaultValues.tags);

  useEffect(() => {
    setBristolMark(defaultValues.bristolMark);
    setDateTime(defaultValues.dateTime);
    setNotes(defaultValues.notes);
    setTags(defaultValues.tags);
  }, [defaultValues]);

  const handleCanceled = () => navigate(-1);

  const handleSubmit = (event) => {
    event.preventDefault();

    const entry = {
      // it will be overriden later for the edit page, so we will not generate new entry from edit page
      id: generateGuid(),
      entryType: 'bowelMovement',
      date: toDateFormat(dateTime),
      dateTime: toDateTimeFormat(dateTime),
      bristolMark: bristolMark,
      tags: tags,
      notes: notes ?? ''
    };

    onSubmit(entry);
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <CommonDiaryEntryForm
        dateTime={dateTime}
        handleCanceled={handleCanceled}
        setDateTime={setDateTime}
        title={title}>
        <Grid item xs={12}>
          <Typography id="input-slider" gutterBottom>
            {t('bristolScale')}
          </Typography>
          <Slider
            aria-label={t('bristolScale')}
            value={bristolMark}
            onChange={(newValue) => setBristolMark(newValue.target.value)}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={1}
            max={bristolMarks.length}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label={t('notes')}
            multiline
            rows={4}
            value={notes}
            onChange={(newValue) => setNotes(newValue.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <FavouritesTagsInput
            label={t('tagsLabel')}
            tags={tags ?? []}
            favouriteType="tags"
            updateTags={setTags}
          />
        </Grid>

        <Grid item xs={12}>
          <Button type="submit" variant="contained">
            {submitText}
          </Button>
          <Button sx={{ ml: 2 }} onClick={handleCanceled}>
            {t('cancel')}
          </Button>
        </Grid>
      </CommonDiaryEntryForm>
    </form>
  );
}

export default BowelMovementForm;
