import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Grid, TextField, Slider } from '@mui/material';
import { toDateFormat, toDateTimeFormat } from 'src/utils/datetime-utils';
import NumberInput from 'src/components/input/NumberInput';
import CommonDiaryEntryForm from '../CommonDiaryEntryForm';
import { generateGuid } from 'src/utils/common-utils';
import FavouritesTagsInput from 'src/components/input/FavouritesTagsInput';
import SymptomsTagsInput from 'src/components/input/SymptomsTagsInput';
import SymptomsFavouritesTutorial from 'src/components/SymptomsFavouritesTutorial';
import { getAllSymptoms } from 'src/services/api';
import { useTranslation } from 'react-i18next';

function SymptomForm({ title, submitText, onSubmit, defaultValues }) {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const [symptoms, setSymptoms] = useState(defaultValues?.symptoms);
  const [dateTime, setDateTime] = useState(defaultValues?.dateTime);
  const [severity, setSeverity] = useState(defaultValues?.severity);
  const [minutesDuration, setMinutesDuration] = useState(defaultValues?.minutesDuration);
  const [hoursDuration, setHoursDuration] = useState(defaultValues?.hoursDuration);
  const [notes, setNotes] = useState(defaultValues?.notes);
  const [tags, setTags] = useState(defaultValues?.tags);
  const [aiSymptoms, setAiSymptoms] = useState([]);

  useEffect(() => {
    async function load() {
      const currentLanguage = i18n.language;

      const allSymptoms = await getAllSymptoms(currentLanguage);
      setAiSymptoms(allSymptoms);

      setSymptoms(defaultValues.symptoms);
      setDateTime(defaultValues.dateTime);
      setSeverity(defaultValues.severity);
      setMinutesDuration(defaultValues.minutesDuration);
      setHoursDuration(defaultValues.hoursDuration);
      setNotes(defaultValues.notes);
      setTags(defaultValues.tags);
    }

    load();
  }, [defaultValues, i18n.language]);

  const handleCanceled = () => navigate(-1);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!symptoms || symptoms.length === 0) {
      alert(t('symptom.sypmtomValidationMessage'));
      return;
    }

    if (isNaN(hoursDuration)) {
      alert(t('common.correctHoursValidationMessage'));
      return;
    }

    if (isNaN(minutesDuration)) {
      alert(t('common.correctMinutesValidationMessage'));
      return;
    }

    const entry = {
      // it will be overriden later for the edit page, so we will not generate new entry from edit page
      id: generateGuid(),
      entryType: 'symptom',
      date: toDateFormat(dateTime),
      dateTime: toDateTimeFormat(dateTime),
      symptoms: symptoms,
      severity: severity,
      hoursDuration: hoursDuration,
      minutesDuration: minutesDuration,
      tags: tags,
      notes: notes ?? ''
    };

    onSubmit(entry);
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <CommonDiaryEntryForm
        dateTime={dateTime}
        handleCanceled={handleCanceled}
        setDateTime={setDateTime}
        hoursDuration={hoursDuration}
        setHoursDuration={setHoursDuration}
        minutesDuration={minutesDuration}
        setMinutesDuration={setMinutesDuration}
        title={title}>
        <Grid item xs={12} sm={6}>
          <SymptomsTagsInput
            label={t('symptom.symptomLabel')}
            tags={symptoms ?? []}
            favouriteType="symptoms"
            updateTags={(values) => setSymptoms(values)}
            limit={1}
            aiItems={aiSymptoms}
          />
        </Grid>

        <Grid item>
          <SymptomsFavouritesTutorial />
        </Grid>

        <Grid item xs={12}>
          <Typography id="input-slider" gutterBottom>
            {t('severity')}
          </Typography>
          <Slider
            aria-label={t('severity')}
            value={severity}
            onChange={(newValue) => setSeverity(newValue.target.value)}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={0}
            max={10}
          />
        </Grid>

        <Grid item xs={6}>
          <NumberInput
            label={t('hoursDuration')}
            number={hoursDuration}
            updateNumber={setHoursDuration}
          />
        </Grid>

        <Grid item xs={6}>
          <NumberInput
            label={t('minutesDuration')}
            number={minutesDuration}
            updateNumber={setMinutesDuration}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label={t('notesLabel')}
            multiline
            rows={4}
            value={notes}
            onChange={(newValue) => setNotes(newValue.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <FavouritesTagsInput
            label={t('tags')}
            tags={tags ?? []}
            favouriteType="tags"
            updateTags={setTags}
          />
        </Grid>

        <Grid item xs={12}>
          <Button type="submit" variant="contained">
            {submitText}
          </Button>
          <Button sx={{ ml: 2 }} onClick={handleCanceled}>
            {t('cancel')}
          </Button>
        </Grid>
      </CommonDiaryEntryForm>
    </form>
  );
}

export default SymptomForm;
