import React, { useState } from 'react';
import MedicationForm from './MedicationForm';
import { fromDateTimeFormat, getDateTimeFromQueryOrNow } from 'src/utils/datetime-utils';
import { useCreateEntry } from 'src/utils/diary-form-utils';
import { t } from 'i18next';

function CreateMedication() {
  const date = getDateTimeFromQueryOrNow();

  const [defaultValues] = useState({
    entryType: 'medication',
    medication: [],
    dateTime: fromDateTimeFormat(date),
    hoursDuration: 0,
    minutesDuration: 5,
    notes: '',
    tags: [t('medication.medicationTag')]
  });

  const onSubmit = useCreateEntry();

  return (
    <MedicationForm
      title={t('medication.addMedication')}
      submitText={t('create')}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
    />
  );
}

export default CreateMedication;
