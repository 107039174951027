import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Grid, Box, MenuItem, Select } from '@mui/material';
import { getDiaryEntriesForWeek as getDiaryEntriesForRange } from 'src/services/firestore-storage';
import { addDays, calculateDateRange, getCurrentDate, getDatesForRange } from 'src/utils/datetime-utils';
import { aggregateItems } from 'src/domain/aggregation-service';
import InfoPane from 'src/pages/reports-tab/InfoPane';
import i18next from 'i18next'; // Import i18next
import CustomCalendar from 'src/components/datetime/CustomCalendar';
import DiaryItems from '../diary/DiaryItems';
import { useTranslation } from 'react-i18next';
import { getMockedDiaryItems } from 'src/services/mocked.diary-items';
import PainsChart from './charts/PainsChart';
import SymptomsChart from './charts/SymptomsChart';
import FoodChart from './charts/FoodChart';
import MedicationsChart from './charts/MedicationChart';
import ActivitiesChart from './charts/ActivityChart';
import BowelMovementChart from './charts/BowelMovementChart';
import { useUser } from 'src/components/loader/user/UserLoader';
import { isUserPremium } from 'src/services/auth-service';
import ActionButtonsPane from './ActionButtonsPane';

function ReportsPage() {
  const { t, i18n } = useTranslation();
  const { user } = useUser();

  const [daysCount, setDaysCount] = useState(7);
  const [startDate, setStartDate] = useState(calculateDateRange(addDays(getCurrentDate(), -(daysCount - 1)), daysCount).startDate);
  const [endDate, setEndDate] = useState(calculateDateRange(addDays(getCurrentDate(), -(daysCount - 1)), daysCount).endDate);

  const [items, setItems] = useState([]);
  const [complaintItems, setComplaintItems] = useState([]);
  const [aggregated, setAggregated] = useState({});

  const featchItems = useCallback(async () => {
    const datesRange = getDatesForRange(startDate, endDate);
    let items = await getDiaryEntriesForRange(user.id, datesRange);

    if (user.isNewAccount && items.length === 0) {
      items = getMockedDiaryItems(i18next.language);
    }

    const aggregated = aggregateItems(items, datesRange);

    const symptoms = items.filter((item) => item.entryType === 'symptom');
    const pains = items.filter((item) => item.entryType === 'pain');

    const complaintItems = symptoms.concat(pains);

    setComplaintItems(complaintItems);
    setItems(items);
    setAggregated(aggregated);
  }, [startDate, daysCount]);

  useEffect(() => {
    async function load() {
      featchItems();
    }

    load();
  }, [featchItems, i18n.language, daysCount, startDate]);

  return (
    <>
      <Select
        labelId="date-range-selector"
        id="date-range-selector"
        value={daysCount}
        onChange={(e) => {
          const newDaysCount = parseInt(e.target.value);
          const newDateRange = calculateDateRange(addDays(getCurrentDate(), -(newDaysCount - 1)), newDaysCount)

          setDaysCount(newDaysCount);
          setStartDate(newDateRange.startDate);
          setEndDate(newDateRange.endDate);
        }}>
        <MenuItem value={7}>{t('7Days')}</MenuItem>
        {isUserPremium(user) && <MenuItem value={14}>{t('14Days')}</MenuItem>}
        {isUserPremium(user) && <MenuItem value={30}>{t('30Days')}</MenuItem>}
      </Select>

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6}>

          <CustomCalendar
            label={t('startDateLabel')}
            date={startDate}
            updateDate={(newValue) => {
              setStartDate(newValue);
              setEndDate(addDays(newValue, daysCount));
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CustomCalendar label={t('endDateLabel')} date={endDate} disabled={true} todayButton={false} />
        </Grid>
      </Grid>

      <ActionButtonsPane items={complaintItems} />

      <Grid container spacing={2} sx={{ mt: '5px' }}>
        <Grid item>
          <InfoPane inputAggregated={aggregated} />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: '5px' }}>
        {aggregated.loadedPains?.count > 0 && (
          <Grid item xs={12} lg={6}>
            <PainsChart inputAggregated={aggregated} />
          </Grid>
        )}

        {aggregated.loadedSymptoms?.count > 0 && (
          <Grid item xs={12} lg={6}>
            <SymptomsChart inputAggregated={aggregated} />
          </Grid>
        )}

        {aggregated.loadedMedications?.count > 0 && (
          <Grid item xs={12} lg={6}>
            <MedicationsChart inputAggregated={aggregated} />
          </Grid>
        )}

        {aggregated.loadedActivities?.count > 0 && isUserPremium(user) && (
          <Grid item xs={12} lg={6}>
            <ActivitiesChart inputAggregated={aggregated} />
          </Grid>
        )}

        {aggregated.loadedFoods?.count > 0 && isUserPremium(user) && (
          <Grid item xs={12} lg={6}>
            <FoodChart inputAggregated={aggregated} />
          </Grid>
        )}

        {aggregated.loadedBowelMovements?.count > 0 && (
          <Grid item xs={12} lg={6}>
            <BowelMovementChart inputAggregated={aggregated} />
          </Grid>
        )}
      </Grid>

      <br />

      <DiaryItems items={items} load={featchItems}></DiaryItems>
    </>
  );
}

export default ReportsPage;
