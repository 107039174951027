import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import CommonDiaryItem from '../CommonDiaryItem';
import { useTranslation } from 'react-i18next';

function WeightItem({ item, handleDelete, editRoute }) {
  const { t } = useTranslation();

  return (
    <CommonDiaryItem item={item} handleDelete={handleDelete} editRoute={editRoute}>
      <Stack
        spacing={{ xs: 1, sm: 2 }}
        direction="row"
        justifyContent="space-between"
        useFlexGap
        flexWrap="wrap">
        <Typography variant="h6" color="#5280EB">
          <img style={{ verticalAlign: 'sub' }} src="/monitor-weight.svg" /> {t('weight.weightLabel')} {item?.weight} {t('weight.weightUnits')}
        </Typography>

        <Typography variant="body2">{item?.dateTime}</Typography>
      </Stack>

      <Divider></Divider>
    </CommonDiaryItem>
  );
}

export default WeightItem;
