import { Grid, IconButton, Typography, Box } from '@mui/material';
import FavouritesTutorial from 'src/components/FavouritesTutorial';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import CustomCalendar from 'src/components/datetime/CustomCalendar';
import { useEffect, useState } from 'react';
import CustomTimePicker from 'src/components/datetime/CustomTimePicker';
import { useTranslation } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';
import { getBeginningOfDayTime } from 'src/utils/datetime-utils';

function CommonDiaryEntryForm({
  title,
  handleCanceled,
  dateTime,
  setDateTime,
  hoursDuration, // can be undefined, used in pain and symptom
  setHoursDuration, // can be undefined, used in pain and symptom
  minutesDuration, // can be undefined, used in pain and symptom
  setMinutesDuration, // can be undefined, used in pain and symptom
  children }) {
  const { t } = useTranslation();
  const [date, setDate] = useState(dateTime);
  const [time, setTime] = useState(dateTime);

  const [isWholeDay, setIsWholeDay] = useState(false);
  const [prevTime, setPrevTime] = useState(dateTime);
  const [prevHoursDuration, setPrevHoursDuration] = useState(hoursDuration);
  const [prevMinutesDuration, setPrevMinutesDuration] = useState(minutesDuration);

  useEffect(() => {
    setDate(dateTime);
    setTime(dateTime);

    if (!isDurationComponent()) {
      return;
    }

    const hoursDurationParsed = Number.parseInt(hoursDuration);
    setIsWholeDay(hoursDurationParsed >= 24);
  }, [dateTime, hoursDuration, minutesDuration]);

  function changeWholeDayTimeRange(isWholeDay) {
    if (!isDurationComponent()) {
      return;
    }
    
    setIsWholeDay(isWholeDay);

    setPrevHoursDuration(hoursDuration);
    setPrevMinutesDuration(minutesDuration);
    setPrevTime(time);

    if (isWholeDay) {
      updateTime(getBeginningOfDayTime());

      setHoursDuration(24);
      setMinutesDuration(0);
    } else {
      updateTime(prevTime);

      setHoursDuration(prevHoursDuration);
      setMinutesDuration(prevMinutesDuration);
    }
  }

  function updateTime(updatedTime) {
    setTime(updatedTime);
    populateDateTime(date, updatedTime);
  }

  function updateDate(updatedDate) {
    setDate(updatedDate);
    populateDateTime(updatedDate, time);
  }

  function populateDateTime(updatedDate, updatedTime) {
    const combinedDate = updatedDate
      .clone()
      .hour(updatedTime.hour())
      .minute(updatedTime.minute())
      .second(updatedTime.second());

    setDateTime(combinedDate);
  }

  function isDurationComponent() {
    const isDurationComponent = !!setHoursDuration && !!setMinutesDuration;
    return isDurationComponent;
  }

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex' }}>
                <IconButton sx={{ padding: '2px 3px 2px 13px' }} onClick={handleCanceled}>
                  <ArrowBackIcon />
                </IconButton>
                <Typography variant="h4" align="left" alignContent="center" sx={{ display: 'flex' }}>
                  {title}
                </Typography>
              </Box>

            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomCalendar date={date} updateDate={updateDate} />
            </Grid>

            <Grid item xs={12} sm={6} alignItems="center">
              <CustomTimePicker time={time} label={t('common.time')} updateTime={updateTime} />

              {isDurationComponent() && <Grid container alignItems="center">
                <Typography>{t('isWholeDay')}</Typography>
                <Checkbox
                  checked={isWholeDay}
                  onChange={(e) => changeWholeDayTimeRange(e.target.checked)}
                  inputProps={{ 'aria-label': t('isWholeDay') }}
                />
              </Grid>}
            </Grid>

            <Grid item>
              <FavouritesTutorial />
            </Grid>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CommonDiaryEntryForm;
