import { Button, Paper, Typography } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import { getCurrentDate } from 'src/utils/datetime-utils';
import { useTranslation } from 'react-i18next';

function CustomCalendar({ date, updateDate, label, todayButton = true, disabled = false }) {
  const { t } = useTranslation();

  return (
    <Paper>
      {label && (
        <Typography sx={{ paddingLeft: '8px', paddingTop: '5px' }}>
          <b>{label}</b>
        </Typography>
      )}
      <DateCalendar
        value={date}
        onChange={(newValue) => updateDate(newValue)}
        sx={{ width: '100% ', justifyContent: 'space-around !important' }}
        disabled={disabled}
      />
      <Button
        sx={{ height: '100%' }}
        onClick={() => updateDate(getCurrentDate())}
        type="button"
        disabled={!todayButton}>
        {t('goToToday')}
      </Button>
    </Paper>
  );
}

export default CustomCalendar;
