const { Helmet } = require('react-helmet');
import { useTranslation } from 'react-i18next';

function Seo() {
  const { t } = useTranslation();
  const textDescription = t('appDescription');

  return (
    <Helmet>
      <title>{t('symptomDiary')}</title>
      <meta name="description" content={textDescription} />

      <meta property="og:title" content="Symptom Diary" />
      <meta property="og:description" content={textDescription} />
      <meta property="og:image" content="/icon.jpg" />
      <meta property="og:type" content="website" />
    </Helmet>
  );
}

export default Seo;
