import React, { useState } from 'react';
import SymptomForm from 'src/pages/diary/symptom/SymptomForm';
import { fromDateTimeFormat, getDateTimeFromQueryOrNow } from 'src/utils/datetime-utils';
import { useCreateEntry } from 'src/utils/diary-form-utils';
import { useTranslation } from 'react-i18next';

function CreateSymptom() {
  const { t } = useTranslation();
  const date = getDateTimeFromQueryOrNow();

  const [defaultValues] = useState({
    entryType: 'symptom',
    symptoms: [],
    dateTime: fromDateTimeFormat(date),
    severity: 3,
    hoursDuration: '',
    minutesDuration: '',
    notes: '',
    tags: [t('symptom.symptomTag')]
  });

  const onSubmit = useCreateEntry();

  return (
    <SymptomForm
      title={t('symptom.addSymptom')}
      submitText={t('create')}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
    />
  );
}

export default CreateSymptom;
