import { useState, useEffect } from 'react';
import {
  Button,
  Divider,
  Link,
  BottomNavigation as MuiBottomNavigation,
  BottomNavigationAction as MuiBottomNavigationAction,
  Typography
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SearchIcon from '@mui/icons-material/Search';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import { Box, Toolbar, Container } from '@mui/material';
import { deleteUserData } from 'src/services/firestore-storage';
import ModalConfirm from '../ModalConfirm';
import { useTranslation } from 'react-i18next';
import { useUser } from '../loader/user/UserLoader';

function Footer() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();

  const [selected, setSelected] = useState(0);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);
      await deleteUserData(user.id);
    } finally {
      setOpen(false);
      setLoading(false);
      window.location.reload();
    }
  };

  useEffect(() => {
    const routes = {};
    routes['/diary'] = 0;
    routes['/reports'] = 1;
    routes['/search'] = 2;

    const index = routes[location.pathname];
    setSelected(index === undefined ? -1 : index);
  }, [location]);

  return (
    <>
      <Box
        sx={{
          marginTop: 'auto',
          pb: '60px'
        }}>
        <Divider sx={{ mb: '3px' }} />
        <Container sx={{ maxWidth: '800px' }}>
          <Toolbar disableGutters>
            <Box>
              <Box>
                <Link href={`https://blog.symptom-diary.com/blogs/2023-01-09-symptom-diary-guide-${i18n.language}`} underline="hover" sx={{ color: "#5280eb" }}>
                  <Typography variant="body2">{t('guide')}</Typography>
                </Link>
              </Box>
              <Box>
                <Link href="/terms-of-use" underline="hover" sx={{ color: "#5280eb" }}>
                  <Typography variant="body2">{t('termsOfUse')}</Typography>
                </Link>
              </Box>
              <Box>
                <Link href="/privacy-policy" underline="hover" sx={{ color: "#5280eb" }}>
                  <Typography variant="body2">{t('privacyPolicy')}</Typography>
                </Link>
              </Box>
              <Box>
                <Typography variant="body2">{t('support')}: symptoms.diary@gmail.com</Typography>
              </Box>

              <br />

              <Box>
                <Typography variant="body2">{t('copyright')}</Typography>
              </Box>
            </Box>

            <Box sx={{ flexGrow: 1, display: 'flex' }} />

            <Box>
              {loading ? (
                t('loading')
              ) : (
                <Button
                  onClick={() => openModal(true)}
                  variant="contained"
                  color="error"
                  sx={{ m: { xs: 0.5, sm: 1 }, p: { xs: 0.5, sm: 1 }, color: 'white' }}>
                  {t('deleteProfile')}
                </Button>
              )}
            </Box>
          </Toolbar>
        </Container>
      </Box>

      <ModalConfirm
        title={loading ? t('loading') : t('deleteProfileWarning')}
        open={open}
        handleCancel={handleClose}
        handleConfirm={handleConfirm}
        handleClose={handleClose}
      />

      <MuiBottomNavigation
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          height: '60px'
        }}
        showLabels
        value={selected}
        onChange={(event, newValue) => {
          setSelected(newValue);
        }}>
        <MuiBottomNavigationAction
          label={t('diary')}
          icon={<FeaturedPlayListIcon />}
          onClick={() => navigate('/diary')}
        />
        <MuiBottomNavigationAction
          label={t('report')}
          icon={<AssessmentIcon />}
          onClick={() => navigate('/reports')}
        />
        <MuiBottomNavigationAction
          label={t('search')}
          icon={<SearchIcon />}
          onClick={() => navigate('/search')}
        />
      </MuiBottomNavigation>
    </>
  );
}

export default Footer;
