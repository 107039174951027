import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, TextField } from '@mui/material';
import { toDateFormat, toDateTimeFormat } from 'src/utils/datetime-utils';
import CommonDiaryEntryForm from '../CommonDiaryEntryForm';
import { generateGuid } from 'src/utils/common-utils';
import FavouritesTagsInput from 'src/components/input/FavouritesTagsInput';
import { useTranslation } from 'react-i18next';

function FoodForm({ title, submitText, onSubmit, defaultValues }) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [dateTime, setDateTime] = useState(defaultValues.dateTime);

  const [drinks, setDrinks] = useState(defaultValues.drinks);
  const [drinksMilliliters, setDrinksMilliliters] = useState(defaultValues.drinksMilliliters);

  const [main, setMain] = useState(defaultValues.main);
  const [mainGrams, setMainGrams] = useState(defaultValues.mainGrams);

  const [sweets, setSweets] = useState(defaultValues.sweets);
  // const [fruits, setFruits] = useState(defaultValues.fruits);
  const [notes, setNotes] = useState(defaultValues.notes);
  const [tags, setTags] = useState(defaultValues.tags);

  useEffect(() => {
    setDateTime(defaultValues.dateTime);

    setDrinks(defaultValues.drinks);
    setDrinksMilliliters(defaultValues.drinksMilliliters);

    setMain(defaultValues.main);
    setMainGrams(defaultValues.mainGrams);

    setSweets(defaultValues.sweets);
    // setFruits(defaultValues.fruits);
    setNotes(defaultValues.notes);
    setTags(defaultValues.tags);
  }, [defaultValues]);

  const handleCanceled = () => navigate(-1);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!main && !drinks && !sweets) {
      alert(t('food.addFoodFieldsValidationMessage'));
      return;
    }

    let localMaingrams = mainGrams;

    if (!localMaingrams) {
      localMaingrams = 0;
    } else {
      if (isNaN(localMaingrams)) {
        alert(t('food.addGramsValidationMessage'));
        return;
      }
    }

    let localDrinksMilliliters = drinksMilliliters;

    if (!localDrinksMilliliters) {
      localDrinksMilliliters = 0;
    } else {
      if (isNaN(localDrinksMilliliters)) {
        alert(t('food.addMillilitersValidationMessage'));
        return;
      }
    }

    const entry = {
      // it will be overriden later for the edit page, so we will not generate new entry from edit page
      id: generateGuid(),
      entryType: 'food',
      date: toDateFormat(dateTime),
      dateTime: toDateTimeFormat(dateTime),
      drinks: drinks,
      drinksMilliliters: localDrinksMilliliters,
      main: main,
      mainGrams: localMaingrams,
      sweets: sweets,
      // 'fruits': fruits,
      tags: tags,
      notes: notes ?? ''
    };

    onSubmit(entry);
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <CommonDiaryEntryForm
        dateTime={dateTime}
        handleCanceled={handleCanceled}
        setDateTime={setDateTime}
        title={title}>
        <Grid item xs={9} sm={6}>
          <FavouritesTagsInput
            label={t('food.mainMealLabel')}
            tags={main ?? []}
            favouriteType="main"
            updateTags={setMain}
          />
        </Grid>

        <Grid item xs={3} sm={6}>
          <TextField
            label={t('common.g')}
            value={mainGrams}
            onChange={(newValue) => setMainGrams(newValue.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={9} sm={6}>
          <FavouritesTagsInput
            label={t('food.drinksLabel')}
            tags={drinks ?? []}
            favouriteType="drinks"
            updateTags={setDrinks}
          />
        </Grid>

        <Grid item xs={3} sm={6}>
          <TextField
            label={t('common.ml')}
            value={drinksMilliliters}
            onChange={(newValue) => setDrinksMilliliters(newValue.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FavouritesTagsInput
            label={t('food.sweetsLabel')}
            tags={sweets ?? []}
            favouriteType="sweets"
            updateTags={setSweets}
          />
        </Grid>

        {/* <Grid item xs={12} sm={6}>
                    <TagsInput
                        label="Fruits (e.g. 'apple, 2 oranges')"
                        tags={fruits ?? []}
                        favouriteType="fruits"
                        updateTags={setFruits}
                    />
                </Grid> */}

        <Grid item xs={12}>
          <TextField
            label={t('notesLabel')}
            multiline
            rows={4}
            value={notes}
            onChange={(newValue) => setNotes(newValue.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <FavouritesTagsInput
            label={t('tagsLabel')}
            tags={tags ?? []}
            favouriteType="tags"
            updateTags={setTags}
          />
        </Grid>

        <Grid item xs={12}>
          <Button type="submit" variant="contained">
            {submitText}
          </Button>
          <Button sx={{ ml: 2 }} onClick={handleCanceled}>
            {t('cancel')}
          </Button>
        </Grid>
      </CommonDiaryEntryForm>
    </form>
  );
}

export default FoodForm;
