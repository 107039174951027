import { getIdToken } from './auth-service';

export async function test() {
  try {
    const token = await getIdToken();

    const response = await fetch('http://localhost:5000/test', {
      method: 'GET', // You can adjust the HTTP method as needed
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // Parse the response JSON if needed
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export async function predictDisease(symptoms, lang) {
  try {
    const response = await fetch(`${process.env.REACT_APP_AI_HOST}/predict-disease?lang=${lang}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(symptoms)
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const desiases = await response.json();

    return desiases;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

export async function getAllSymptoms(lang) {
  try {
    const response = await fetch(`${process.env.REACT_APP_AI_HOST}/symptoms?lang=${lang}`, {
      method: 'GET'
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const dict = await response.json();
    const list = Object.keys(dict);

    return list;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

export async function getAllPains(lang) {
  try {
    const response = await fetch(`${process.env.REACT_APP_AI_HOST}/pains?lang=${lang}`, {
      method: 'GET'
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const dict = await response.json();
    const list = Object.keys(dict);

    return list;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}
