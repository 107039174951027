import { createContext } from 'react';

export const FavouritesContext = createContext({
  favourites: {},
  // eslint-disable-next-line no-unused-vars
  updateFavourites: (type, item) => {}
});

// deletes item from favouriteType if it exists, and adds if it does not.
export function calculateNewFavourite(favourites, favouriteType, item) {
  const newFavourites = favourites ? { ...favourites } : {};
  const obj = newFavourites[favouriteType] ?? {};

  if (Object.hasOwn(obj, item)) {
    // if there is item in favourites - we want to exclude
    delete obj[item];
    newFavourites[favouriteType] = obj;
  } else {
    // otherwise we want to add it to favourites
    obj[item] = item;
    newFavourites[favouriteType] = obj;
  }

  return newFavourites;
}
