import { Button, Dialog, DialogTitle, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';

function ModalConfirm({ title, open, handleCancel, handleConfirm, handleClose }) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{t(title)}</DialogTitle>
      <DialogActions>
        <Button onClick={handleConfirm}>{t('yes')}</Button>
        <Button onClick={handleCancel} autoFocus>
          {t('no')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalConfirm;
