import { useEffect, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, Select } from '@mui/material';
import CommonChart from './CommonChart';

function MedicationsChart({ inputAggregated }) {
  const { t } = useTranslation();

  const [medicationCountName, setMedicationCountName] = useState('');
  const [chartData, setChartData] = useState([]);
  const [medicationNames, setMedicationNames] = useState([]);
  const [chartMedicationName, setChartMedicationName] = useState('');

  useEffect(() => {
    const medicationsMap = inputAggregated?.loadedMedications?.medicationsMap;
    const datesRange = inputAggregated?.datesRange;

    const names = medicationsMap ? Object.keys(medicationsMap) : []

    let medicationName;

    if (names.includes(chartMedicationName)) {
      medicationName = chartMedicationName;
    }

    if (!chartMedicationName) {
      medicationName = names[0] ?? '';
    }

    if (!medicationName || !medicationsMap || !datesRange) {
      setMedicationNames([]);
      setChartMedicationName('');
      setChartData([]);
      setMedicationCountName('');
      return;
    }

    const medicationCountChartObj = {};

    for (let date of datesRange) {
      medicationCountChartObj[medicationName] ??= [];

      const medicationsOnDate = medicationsMap[medicationName][date];

      let medicationCount = 0;

      if (medicationsOnDate) {
        medicationCount = medicationsOnDate.length;
      }

      medicationCountChartObj[medicationName].push(medicationCount);
    }

    const chartData = [];
    const medicationCountName = `${medicationName} ${t('count')}`;

    for (let i = 0; i < datesRange.length; i++) {
      let item = {
        name: datesRange[i]
      };

      item[medicationCountName] = medicationCountChartObj[medicationName][i];

      chartData.push(item);
    }

    setMedicationNames(names);
    setChartMedicationName(medicationName);
    setMedicationCountName(medicationCountName);
    setChartData(chartData);
  }, [inputAggregated, chartMedicationName]);

  return (
    <>
      {medicationNames && medicationNames.length > 0 && (
        <Select
          labelId="medication-selector"
          id="medication-selector"
          value={chartMedicationName}
          onChange={(e) => { setChartMedicationName(e.target.value) }}>
          {medicationNames.map(p => <MenuItem key={p} value={p}>{p}</MenuItem>)}
        </Select>
      )}
      <Box sx={{ml: -7}}>
        <CommonChart
          chartName={t('medicationChart')}
          chartData={chartData}
          lines={[{ name: medicationCountName, color: "#82ca9d" }]} />
      </Box>
    </>
  );
}

export default MedicationsChart;
