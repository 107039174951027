import React, { useState } from 'react';
import {
  deleteDiaryById,
  getDiaryEntryById,
  getTagsMap,
  upsertTagsMap
} from 'src/services/firestore-storage';
import { Grid } from '@mui/material';
import { removeTagFromMap } from 'src/domain/tags-service';
import ModalConfirm from 'src/components/ModalConfirm';
import PainItem from './pain/PainItem';
import SymptomItem from './symptom/SymptomItem';
import FoodItem from './food/FoodItem';
import ActivityItem from './activity/ActivityItem';
import BowelMovementItem from './bowel-movement/BowelMovementItem';
import MedicationItem from './medication/MedicationItem';
import WeightItem from './weight/WeightItem';
import { useTranslation } from 'react-i18next';
import { useUser } from 'src/components/loader/user/UserLoader';

function DiaryItems({ items, load }) {
  const { user } = useUser();
  const { t } = useTranslation();

  const diaryItemsMapping = {
    pain: (item, handleDelete) => (
      <PainItem item={item} handleDelete={handleDelete} editRoute="edit-pain" />
    ),
    symptom: (item, handleDelete) => (
      <SymptomItem item={item} handleDelete={handleDelete} editRoute="edit-symptom" />
    ),
    food: (item, handleDelete) => (
      <FoodItem item={item} handleDelete={handleDelete} editRoute="edit-food" />
    ),
    activity: (item, handleDelete) => (
      <ActivityItem item={item} handleDelete={handleDelete} editRoute="edit-activity" />
    ),
    bowelMovement: (item, handleDelete) => (
      <BowelMovementItem item={item} handleDelete={handleDelete} editRoute="edit-bowelMovement" />
    ),
    medication: (item, handleDelete) => (
      <MedicationItem item={item} handleDelete={handleDelete} editRoute="edit-medication" />
    ),
    weight: (item, handleDelete) => (
      <WeightItem item={item} handleDelete={handleDelete} editRoute="edit-weight" />
    )
  };

  const [open, setOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState({});

  const handleClose = () => {
    setCurrentItem({});
    setOpen(false);
  };

  const handleDelete = (item) => {
    setCurrentItem(item);
    setOpen(true);
  };

  // eslint-disable-next-line no-unused-vars
  const handleConfirm = async (id, date) => {
    try {
      const entry = await getDiaryEntryById(user.id, id);
      await deleteDiaryById(id, user.id);

      const tagsEntry = (await getTagsMap(user.id)) ?? {};

      for (let tag of entry.tags) {
        removeTagFromMap(tagsEntry, tag, entry.id);
      }

      await upsertTagsMap(user.id, tagsEntry);

      // TODO performance increase - just remove item from `items` variable and rerender component without loading from storage
      await load();
    } finally {
      setOpen(false);
    }
  };

  return (
    <>
      {items && items.length > 0 ? (
        <>
          {items.map((item) => (
            <Grid key={item?.id} sx={{ width: '100%' }}>
              {diaryItemsMapping[item.entryType]?.(item, handleDelete)}
            </Grid>
          ))}
          <ModalConfirm
            title={`deleteWarning`}
            open={open}
            handleCancel={handleClose}
            handleConfirm={() => handleConfirm(currentItem.id, currentItem.date)}
            handleClose={handleClose}
          />
        </>
      ) : (
        <Grid item sx={{ width: '100%' }}>
          {t('common.noItemsForThisDate')}
        </Grid>
      )}
    </>
  );
}

export default DiaryItems;
