import React from 'react';
import { Card, CardContent, Typography, Button } from '@mui/material';
import { Link } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { useTranslation } from 'react-i18next';

function PlanCard({ plan }) {
  const { t } = useTranslation();

  function renderPlanButton() {
    if (plan.disabled) {
      return (
        <Button disabled={true} variant="contained" color="primary">
          {plan.name}
        </Button>
      );
    }

    if (plan.selected) {
      return (
        <Link href={plan.cancelLink}>
          <Button variant="contained" color="primary">
            <DoneIcon /> {t('cancel')}
          </Button>
        </Link>
      );
    }

    return (
      <Link href={plan.paymentLink}>
        <Button variant="contained" color="primary">
          {plan.name}
        </Button>
      </Link>
    );
  }

  return (
    <Card sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardContent sx={{ flex: '1 0 auto' }}>
        <Typography variant="h3" component="div">
          {plan.name}
        </Typography>
        <br />
        <Typography variant="h5" component="div">
          {t('features')}:
        </Typography>
        <br />
        {plan.features.map((f) => (
          <Typography key={f} variant="body2">
            - {f}
          </Typography>
        ))}
      </CardContent>

      <br />

      <CardContent>
        <Typography variant="h6">{t('price.price')}: {plan.price ? '$' + plan.price : t('price.free')}</Typography>

        <br />

        {renderPlanButton()}
      </CardContent>
    </Card>
  );
}

export default PlanCard;
