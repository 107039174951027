import { Button, Dialog, DialogTitle, DialogActions, Box, Typography, LinearProgress, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

function AiPopUp({ diseases, open, handleClick }) {
    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            onClose={handleClick}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title"><b>{t('aiResults')}</b></DialogTitle>

            <DialogContent>
                {diseases.map((disease, index) => (
                    <Box key={disease.name} sx={{ marginBottom: 2 }}>
                        <Typography sx={{ fontWeight: index < 3 ? 'bold' : 'normal' }}>
                            {disease.disease}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: '100%', mr: 1 }}>
                                <LinearProgress variant="determinate" value={disease.probability} />
                            </Box>
                            <Typography variant="body2">{`${disease.probability}%`}</Typography>
                        </Box>
                    </Box>
                ))}
            </DialogContent>

            <DialogContent>
                <Typography variant="body2">{t('aiDisclaimer')}</Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClick}>{t('ok')}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AiPopUp;