import { useContext, useEffect, useState } from 'react';
import { FavouritesContext } from 'src/components/loader/favourites/FavouritesContext';
import TagsInput from './TagsInput';

function FavouritesTagsInput({
  label,
  tags,
  favouriteType,
  updateTags,
  limit = 100,
  additionalItems = []
}) {
  const [favouriteItems, setFavouriteItems] = useState([]);
  const [stateAdditionalItems, setStateAdditionalItems] = useState([]);
  const { favourites, updateFavourites } = useContext(FavouritesContext);

  const [itemsMetadata, setItemsMetadata] = useState({
    favourites: new Set(),
    aiItems: new Set(),
    warningItems: new Set()
  });

  // We are setting stateAdditionalItems in different useEffect, because otherwise on first load
  // it would be empty. In case we put dependency on additionalItems, it would enter infinite loop.
  useEffect(() => {
    if (stateAdditionalItems.length === 0 && additionalItems.length > 0) {
      setStateAdditionalItems(additionalItems);
    }
  }, [additionalItems]);

  useEffect(() => {
    let localFavourites = [];

    let localMetadata = {
      favourites: new Set(),
      aiItems: new Set(),
      warningItems: new Set()
    };

    if (favourites && favourites[favouriteType]) {
      // eslint-disable-next-line no-unused-labels
      localFavourites = [...Object.keys(favourites[favouriteType])];
      localMetadata.favourites = new Set(localFavourites);
    }

    for (let item of stateAdditionalItems) {
      if (!localMetadata.favourites.has(item)) {
        localFavourites.push(item);
      }
    }

    setItemsMetadata(localMetadata);
    setFavouriteItems(localFavourites);
  }, [favourites, stateAdditionalItems]);

  return (
    <div >
      <TagsInput
        label={label}
        tags={tags}
        updateTags={updateTags}
        favouriteItems={favouriteItems}
        updateFavourites={(i) => {
          updateFavourites(favouriteType, i);
        }}
        limit={limit}
        itemsMetadata={itemsMetadata}
      />
    </div>
  );
}
export default FavouritesTagsInput;
