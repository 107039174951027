import React, { useState } from 'react';
import BowelMovementForm from 'src/pages/diary/bowel-movement/BowelMovementForm';
import { fromDateTimeFormat, getDateTimeFromQueryOrNow } from 'src/utils/datetime-utils';
import { useCreateEntry } from 'src/utils/diary-form-utils';
import { useTranslation } from 'react-i18next';

function CreateBowelMovement() {
  const { t } = useTranslation();
  const date = getDateTimeFromQueryOrNow();

  const [defaultBowelMovements] = useState({
    entryType: 'bowelMovement',
    dateTime: fromDateTimeFormat(date),
    bristolMark: 4,
    notes: '',
    tags: [t('bowelMovement.bowelMovementTag')]
  });

  const onSubmit = useCreateEntry();

  return (
    <BowelMovementForm
      title={t('bowelMovement.addBowelMovement')}
      submitText={t('create')}
      onSubmit={onSubmit}
      defaultValues={defaultBowelMovements}
    />
  );
}

export default CreateBowelMovement;
