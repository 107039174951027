import React, { useState } from 'react';
import PainForm from 'src/pages/diary/pain/PainForm';
import { fromDateTimeFormat, getDateTimeFromQueryOrNow } from 'src/utils/datetime-utils';
import { useCreateEntry } from 'src/utils/diary-form-utils';
import { useTranslation } from 'react-i18next';

function CreatePain() {
  const { t } = useTranslation();
  const date = getDateTimeFromQueryOrNow();

  const [defaultValues] = useState({
    entryType: 'pain',
    pains: [],
    painTypes: [t('common.NA')],
    dateTime: fromDateTimeFormat(date),
    severity: 2,
    hoursDuration: '',
    minutesDuration: '',
    notes: '',
    tags: [t('pain.painTag')]
  });

  const onSubmit = useCreateEntry();

  return (
    <>
      <PainForm
        title={t('pain.addPain')}
        submitText={t('create')}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
      />
    </>
  );
}

export default CreatePain;
