import { GoogleAuthProvider, signOut, signInWithCredential } from 'firebase/auth';
import { getUserProfile, upsertUserProfile } from 'src/services/firestore-storage';
import { getFirebaseAuth } from './firestore-base';
import {
  addDays,
  fromDateTimeFormat,
  getCurrentDate,
  toDateTimeFormat
} from 'src/utils/datetime-utils';
const { v4: uuidv4 } = require('uuid');

const auth = getFirebaseAuth();

const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  prompt: 'select_account'
});

let currentUser = null;
let userChangedFuncs = null;

export async function logOut() {
  await signOut(auth);
}

export async function getIdToken() {
  return await auth.currentUser.getIdToken();
}

export async function getCurrentUser() {
  return currentUser;
}

// when primary google oauth is used
export async function signInWithGoogleCred(idToken) {
  const credential = GoogleAuthProvider.credential(idToken);
  var authResult = await signInWithCredential(auth, credential);

  if (!authResult) {
    console.log("Google didn't return auth results.");
    return;
  }

  const user = authResult.user;

  let profile = await getUserProfile(user?.uid);

  if (!profile) {
    profile = {
      id: user.uid,
      email: user.email,
      role: 'premium',
      subscriptionStartDate: toDateTimeFormat(getCurrentDate()),
      subscriptionEndDate: toDateTimeFormat(addDays(getCurrentDate(), 30)),
      deleted: false,
      isNewAccount: true,
      emailNotifications: {
        enabled: true
      },
      telegramNotifications: {
        enabled: true
      },
      linkToken: uuidv4(),
      lang: 'en',
      aiAnalysisCount: 0
    };

    await upsertUserProfile(profile.id, profile);
    userChangedFuncs.map(f => f(user));
  }

  if (profile.deleted) {
    throw Error('profile is deleted, contact support symptoms.diary@gmail.com');
  }

  await setUser(profile);
  return currentUser;
}

// seems like token lives 1 hour
export async function subscribeToUserUpdate(func) {
  userChangedFuncs ??= [];
  userChangedFuncs.push(func);

  return auth.onAuthStateChanged(async (user) => {
    let profile = await getUserProfile(user?.uid);

    await setUser(profile);

    if (profile?.deleted) {
      console.log('profile is deleted, contact support');
    }

    await func(currentUser);
  });
}

export function isUserPremium(user) {
  return isUserInRole(user, 'premium');
}

export function getUserRole(user) {
  return user?.role;
}

export async function setUser(newUser) {
  currentUser = newUser;
  userChangedFuncs.map(f => f(newUser));

  await ensurePremiumNotExpired(currentUser);
}

async function ensurePremiumNotExpired(user) {
  if (!user) {
    return;
  }

  const currentDate = getCurrentDate();

  if (user.subscriptionEndDate && currentDate >= fromDateTimeFormat(user.subscriptionEndDate)) {
    user.role = 'basic';
    await upsertUserProfile(user.id, user);
  }
}

function isUserInRole(user, role) {
  return user?.role === role;
}
