import { Autocomplete, TextField, Chip } from '@mui/material';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PsychologyIcon from '@mui/icons-material/Psychology';
import WarningIcon from '@mui/icons-material/Warning';

function TagsInput({
                     label,
                     tags,
                     updateTags,
                     favouriteItems,
                     updateFavourites,
                     limit = 100,
                     itemsMetadata
                   }) {
  const [limitReached, setLimitReached] = useState((tags && tags.length >= limit) || false);
  const [metadata, setMetadata] = useState({
    favourites: new Set(),
    aiItems: new Set(),
    warningItems: new Set()
  });
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (favouriteItems) {
      setOptions(favouriteItems);
    }

    // maybe we would need to use spread syntax
    if (itemsMetadata) {
      setMetadata(itemsMetadata);
    }
  }, [favouriteItems, itemsMetadata]);

  const handleKeyDown = (event) => {
    // to not allow user type when limit is reached.
    if (limitReached) {
      event.preventDefault();
    }
  };

  const isInFavourite = (item) => metadata.favourites.has(item);

  return (
    <>
      <Autocomplete
        multiple
        id="tags-filled"
        options={options}
        freeSolo
        autoSelect
        value={tags ?? []}
        onChange={(e, values) => {
          const trimmedValues = values.map(v => v.trim()).filter(v => v.length > 0);

          const isLimitReached = trimmedValues.length >= limit;
          const isOverLimit = trimmedValues.length > limit;

          setLimitReached(isLimitReached);

          // this one needed when we just click on the chip from select list
          if (isOverLimit) {
            trimmedValues.pop();
          }

          updateTags(trimmedValues);
        }}
        getOptionLabel={(o) => o}
        renderOption={(props, option) => (
          <CustomOption {...props}>
            {metadata.favourites && metadata.favourites.has(option) && (
              <FavoriteIcon sx={{ color: '#5280eb', mr: '10px' }} />
            )}
            {metadata.aiItems && metadata.aiItems.has(option) && (
              <PsychologyIcon sx={{ color: '#5280eb', mr: '10px' }} />
            )}
            {metadata.warningItems && metadata.warningItems.has(option) && (
              <WarningIcon sx={{ color: '#1B2A4D', mr: '10px' }} />
            )}
            <span>{option}</span>
          </CustomOption>
        )}
        renderTags={(value, getTagProps) => {
          //value here is [] array of all options
          return value.map((option, index) => (
            <Chip
              sx={{
                height: '100%',
                '& .MuiChip-label': {
                  height: '25px'
                }
              }}
              key={index}
              color={isInFavourite(option) ? 'strongSuccess' : 'primary'}
              id={option}
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              onClick={(e) => updateFavourites(e.target.innerText)}
            />
          ));
        }}
        renderInput={(params) => (
          <TextField
            sx={{
              backgroundColor: 'transparent',
              '& .MuiFilledInput-root': {
                backgroundColor: 'transparent',
                border: '1px solid #aaa',
                borderBottom: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                  border: '1px solid #ccc',
                  borderBottom: 'none'
                },
                '&.Mui-focused': {
                  backgroundColor: 'transparent',
                  border: '1px solid #aaa',
                  borderBottom: 'none'
                }
              }
            }}
            disabled={limitReached}
            autoComplete={limitReached ? 'off' : 'on'}
            onKeyDown={handleKeyDown}
            {...params}
            variant="filled"
            label={label}></TextField>
        )}
      />
    </>
  );
}

const CustomOption = styled('div')({
  display: 'flex',
  alignItems: 'center'
});

export default TagsInput;
