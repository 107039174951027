import {
  collection,
  setDoc,
  doc,
  getDocs,
  getDoc,
  deleteDoc,
  query,
  where,
  limit,
  orderBy,
  startAfter,
  endBefore
} from 'firebase/firestore';
import { getFirestoreDb } from './firestore-base';
import { addDays, getCurrentDate, toDateTimeFormat } from 'src/utils/datetime-utils';

const userCollection = 'users';
const dairySubCollection = 'diary';
const tagsSubCollection = 'tags';
const allTagsDoc = 'all';

const favouritesSubCollection = 'favourites';
const allFavouritesDoc = 'all';

const db = getFirestoreDb();

// ##################
// ### FAVOURITES ###
// ##################
export async function upsertFavourites(userId, obj) {
  if (!userId) {
    console.log('[upsertFavourites]: userId is empty');
    return;
  }

  const favouritesCollection = collection(db, userCollection, userId, favouritesSubCollection);

  const document = doc(favouritesCollection, allFavouritesDoc);

  logFirestoreWrite('upsertFavourites', 1);

  await setDoc(document, obj);
}

export async function getFavourites(userId) {
  if (!userId) {
    console.log('[getFavourites]: userId is empty');
    return;
  }

  const favouritesCollection = collection(db, userCollection, userId, favouritesSubCollection);

  const document = await getDocument(favouritesCollection, allFavouritesDoc);

  logFirestoreRead('getFavourites', 1);

  return document;
}

// #############
// ### TAGS ###
// #############
export async function getTagsMap(userId) {
  if (!userId) {
    console.log('[getTagsMap]: userId is empty');
    return;
  }
  console.log(`[getTagsMap]`);

  const tagsCollection = collection(db, userCollection, userId, tagsSubCollection);
  const doc = await getDocument(tagsCollection, allTagsDoc);

  logFirestoreRead('getTagsMap', 1);

  return doc;
}

export async function upsertTagsMap(userId, entry) {
  if (!userId) {
    console.log('[upsertTagsMap]: userId is empty');
    return;
  }

  const tagsCollection = collection(db, userCollection, userId, tagsSubCollection);
  const document = doc(tagsCollection, allTagsDoc);

  logFirestoreWrite('upsertTagsMap', 1);

  await setDoc(document, entry);
}

// #############
// ### DIARY ###
// #############
export async function getDiaryEntriesByTag(userId, tag, startAfterDateTime, start = null, end = null) {
  if (!userId) {
    console.log('[getDiaryEntriesByTag]: userId is empty');
    return;
  }

  if (!tag || tag.length === 0) {
    console.log('[getDiaryEntriesByTag]: tag is empty');
    return;
  }

  const userDairyCollection = collection(db, userCollection, userId, dairySubCollection);

  let entriesQuery = null;

  if (!start) {
    start = toDateTimeFormat(addDays(getCurrentDate(), -365));
  }

  if (!end) {
    end = toDateTimeFormat(addDays(getCurrentDate(), 1));
  }

  // this is for paging, not for filtering
  if (startAfterDateTime) {
    entriesQuery = query(
      userDairyCollection,
      orderBy('dateTime', 'desc'),
      where('tags', 'array-contains', tag),
      limit(15),
      where('dateTime', '>=', start),
      where('dateTime', '<=', end),
      startAfter(startAfterDateTime)
    );
  } else {
    entriesQuery = query(
      userDairyCollection,
      orderBy('dateTime', 'desc'),
      where('tags', 'array-contains', tag),
      limit(15),
      where('dateTime', '>=', start),
      where('dateTime', '<=', end),
    );
  }

  const docs = await getDocuments(entriesQuery);

  logFirestoreRead('getDiaryEntriesByTag', docs.length);

  return docs;
}

export async function getDiaryEntriesByIds(userId, ids) {
  if (!userId) {
    console.log('[getDiaryEntriesByIds]: userId is empty');
    return;
  }

  if (!ids || ids.length === 0) {
    console.log('[getDiaryEntriesByIds]: userId is empty');
    return;
  }

  const userDairyCollection = collection(db, userCollection, userId, dairySubCollection);
  const entriesQuery = query(
    userDairyCollection,
    orderBy('dateTime', 'desc'),
    where('id', 'in', ids)
  );

  const docs = await getDocuments(entriesQuery);
  logFirestoreRead('getDiaryEntriesByIds', docs.length);

  return docs;
}

export async function getDiaryEntriesForWeek(userId, dates) {
  if (!userId) {
    console.log('[getDiaryEntriesForWeek]: userId is empty');
    return;
  }

  if (!dates || dates.length === 0) {
    console.log('[getDiaryEntriesForWeek]: dates are empty');
    return;
  }

  const userDairyCollection = collection(db, userCollection, userId, dairySubCollection);
  const weekQuery = query(
    userDairyCollection,
    where('date', 'in', dates),
    orderBy('dateTime', 'desc')
  );

  const docs = await getDocuments(weekQuery);

  logFirestoreRead('getDiaryEntriesForWeek', docs.length);

  return docs;
}

export async function getDiaryEntryById(userId, id) {
  if (!userId) {
    console.log('[getDiaryEntryById]: userId is empty');
    return;
  }

  if (!id) {
    console.log('[getDiaryEntryById]: id is empty');
    return;
  }

  const favouritesCollection = collection(db, userCollection, userId, dairySubCollection);

  const document = await getDocument(favouritesCollection, id);

  logFirestoreRead('getDiaryEntryById', 1);

  return document;
}

export async function getDiaryEntriesForDate(userId, date) {
  if (!userId) {
    console.log('[getDiaryEntriesForDate]: userId is empty');
    return;
  }

  if (!date) {
    console.log('[getDiaryEntriesForDate]: date is empty');
    return;
  }

  const userDairyCollection = collection(db, userCollection, userId, dairySubCollection);
  const weekQuery = query(userDairyCollection, where('date', '==', date));

  const docs = await getDocuments(weekQuery);

  logFirestoreRead('getDiaryEntriesForDate', docs.length);

  return docs;
}

export async function upsertDiary(id, entry, userId) {
  if (!userId) {
    console.log('[upsertDiary]: userId is empty');
    return;
  }

  if (!id) {
    console.log('[upsertDiary]: id is empty');
    return;
  }

  const userDairyCollection = collection(db, userCollection, userId, dairySubCollection);
  const document = doc(userDairyCollection, id);

  logFirestoreWrite('upsertDiary', 1);

  await setDoc(document, entry);
}

export async function deleteDiaryById(id, userId) {
  if (!userId) {
    console.log('[deleteDiaryById]: userId is empty');
    return;
  }

  if (!id) {
    console.log('[deleteDiaryById]: id is empty');
    return;
  }

  logFirestoreDelete('deleteDiaryById', 1);

  await deleteDoc(doc(db, userCollection, userId, dairySubCollection, id));
}

// ###################
// ### USERPROFILE ###
// ###################
export async function deleteUserData(userId) {
  if (!userId) {
    console.log('[deleteUserData]: userId is empty');
    return;
  }

  //const userDairyCollection = collection(db, userCollection, userId, dairySubCollection);
  //await deleteDiaryCollection(userId, userDairyCollection);

  //await deleteDoc(doc(db, userCollection, userId, tagsSubCollection, allFavouritesDoc));

  const userProfile = await getUserProfile(userId);
  userProfile.deleted = true;
  userProfile.emailNotifications = false;
  userProfile.telegramNotifications = false;

  if (userProfile.emailNotifications) {
    userProfile.emailNotifications.enabled = false;
  }

  if (userProfile.telegramNotifications) {
    userProfile.telegramNotifications.enabled = false;
  }

  await upsertUserProfile(userId, userProfile);
}

export async function upsertUserProfile(userId, userProfile) {
  if (!userId) {
    console.log('[upsertUserProfile]: userId is empty');
    return;
  }
  const userProfileCollection = collection(db, userCollection);
  const document = doc(userProfileCollection, userId);

  logFirestoreWrite('upsertUserProfile', 1);

  await setDoc(document, userProfile);
}

export async function getUserProfile(userId) {
  if (!userId) {
    console.log('[getUserProfile]: userId is empty');
    return;
  }

  const userProfileCollection = collection(db, userCollection);

  const document = await getDocument(userProfileCollection, userId);

  logFirestoreRead('getUserProfile', 1);

  return document;
}

async function deleteDiaryCollection(userId, collectionToDelete) {
  let docsQuery = query(collectionToDelete, limit(50));
  let docs = await getDocuments(docsQuery);

  while (docs.length > 0) {
    docs.forEach(async (d) => {
      await deleteDiaryById(d.id, userId);
    });

    docsQuery = query(collectionToDelete, limit(50));
    docs = await getDocuments(docsQuery);
  }
}

async function getDocuments(query) {
  const querySnapshot = await getDocs(query);

  const docs = [];

  querySnapshot.forEach((doc) => {
    docs.push(doc.data());
  });

  return docs;
}

async function getDocument(collection, docId) {
  const documentDef = doc(collection, docId);

  const document = await getDoc(documentDef);
  const obj = document.data();

  return obj;
}

function logFirestoreWrite(methodName, numberOfWrites) {
  numberOfWrites = numberOfWrites > 0 ? numberOfWrites : 1;
  console.log(`WRITE [${methodName}] made {${numberOfWrites}} writes`);
}

function logFirestoreRead(methodName, numberOfReads) {
  numberOfReads = numberOfReads > 0 ? numberOfReads : 1;
  console.log(`READ [${methodName}] made {${numberOfReads}} reads`);
}

function logFirestoreDelete(methodName, numberOfDeletes) {
  numberOfDeletes = numberOfDeletes > 0 ? numberOfDeletes : 1;
  console.log(`Delete [${methodName}] made {${numberOfDeletes}} deletes`);
}
