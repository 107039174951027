export const calculateNewTagsEntryFromUpdate = (tagsEntry, oldTags, newTags, diaryEntryId) => {
  const oldTagsSet = new Set(oldTags);
  const newTagsSet = new Set(newTags);

  for (let oldTag of oldTags) {
    if (!newTagsSet.has(oldTag)) {
      removeTagFromMap(tagsEntry, oldTag, diaryEntryId);
    }
  }

  for (let newTag of newTagsSet) {
    if (!oldTagsSet.has(newTag)) {
      addTagToMap(tagsEntry, newTag, diaryEntryId);
    }
  }
};

export const addTagToMap = (tagEntry, tag) => {
  if (!tagEntry.tagsMap) {
    tagEntry.tagsMap = {};
  }

  tagEntry.tagsMap[tag] = tag;
};

export const removeTagFromMap = (tagEntry, tag) => {
  if (!tagEntry.tagsMap) {
    return;
  }

  if (!(tag in tagEntry.tagsMap)) {
    return;
  }

  delete tagEntry.tagsMap[tag];
};
