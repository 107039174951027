import React, { useState } from 'react';
import ActivityForm from 'src/pages/diary/activity/ActivityForm';
import { fromDateTimeFormat, getDateTimeFromQueryOrNow } from 'src/utils/datetime-utils';
import { useCreateEntry } from 'src/utils/diary-form-utils';
import { useTranslation } from 'react-i18next';

function CreateActivity() {
  const { t } = useTranslation();
  const date = getDateTimeFromQueryOrNow();

  const [defaultActivity] = useState({
    entryType: 'activity',
    dateTime: fromDateTimeFormat(date),
    hoursDuration: '',
    minutesDuration: '',
    activities: [],
    notes: '',
    tags: [t('activity.activitiesTag')]
  });

  const onSubmit = useCreateEntry();

  return (
    <ActivityForm
      title={t('activity.addActivity')}
      submitText={t('create')}
      onSubmit={onSubmit}
      defaultValues={defaultActivity}
    />
  );
}

export default CreateActivity;
