import { Chip, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function FavouritesTutorial() {
  const { t } = useTranslation();

  return (
    <Stack sx={{ alignItems: 'center' }} direction="row" flexWrap="wrap">
      <Typography sx={{ mr: 1 }}>{t('makeItemFavourite')}</Typography>
      <Typography>{t('willTurnFrom')}</Typography>
      <Chip
        sx={{ mr: 1, ml: 1 }}
        color="primary"
        id="lighter"
        key="lighter"
        variant="outlined"
        label="lighter"
      />
      <Typography>{t('to')}</Typography>
      <Chip
        sx={{ ml: 1, mr: 1 }}
        color="strongSuccess"
        id="stronger"
        key="stronger"
        variant="outlined"
        label="stronger"
      />
      <Typography>{t('nextTimeSelectWithoutTyping')}</Typography>
    </Stack>
  );
}

export default FavouritesTutorial;
