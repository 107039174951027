import { useEffect, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, Select } from '@mui/material';
import CommonChart from './CommonChart';

function SymptomsChart({ inputAggregated }) {
  const { t } = useTranslation();

  const [symptomCountName, setSymptomCountName] = useState('');
  const [symptomSeverityName, setSymptomSeverityName] = useState('');
  const [chartData, setChartData] = useState([]);
  const [symptomNames, setSymptomNames] = useState([]);
  const [chartSymptomName, setChartSymptomName] = useState('');

  useEffect(() => {
    const symptomsMap = inputAggregated?.loadedSymptoms?.symptomsMap;
    const datesRange = inputAggregated?.datesRange;

    const names = symptomsMap ? Object.keys(symptomsMap) : []
    
    let symptomName;

    if (names.includes(chartSymptomName)) {
      symptomName = chartSymptomName;
    }
    
    if (!chartSymptomName) {
      symptomName = names[0] ?? '';
    }

    if (!symptomName || !symptomsMap || !datesRange) {
      setSymptomNames([]);
      setChartSymptomName('');
      setChartData([]);
      setSymptomCountName('');
      setSymptomSeverityName('')
      return;
    }

    const symptomCountChartObj = {};
    const symptomSeverityChartObj = {};

    for (let date of datesRange) {
      symptomCountChartObj[symptomName] ??= [];
      symptomSeverityChartObj[symptomName] ??= [];

      const symptomsOnDate = symptomsMap[symptomName][date];

      let painCount = 0;
      let painSeverity = 0;

      if (symptomsOnDate) {
        painCount = symptomsOnDate.length;
        painSeverity = Math.max(...symptomsOnDate.map(obj => obj.severity));
      }

      symptomCountChartObj[symptomName].push(painCount);
      symptomSeverityChartObj[symptomName].push(painSeverity);
    }

    const chartData = [];
    const symptomCountName = `${symptomName} ${t('count')}`;
    const symptomSeverityName = `${symptomName} ${t('severity')}`;

    for (let i = 0; i < datesRange.length; i++) {
      let item = {
        name: datesRange[i]
      };

      item[symptomCountName] = symptomCountChartObj[symptomName][i];
      item[symptomSeverityName] = symptomSeverityChartObj[symptomName][i];

      chartData.push(item);
    }
    // {
    //     "obj": {
    //         "nausea count": [0, 0, 0, 0, 0, 0, 0, 1],
    //         "nausea severity": [0, 0, 0, 0, 0, 0, 0, 5],
    //     },
    //     "datesRange": ["2023-07-27", "2023-07-28", "2023-07-29", "2023-07-30", "2023-07-31", "2023-08-01", "2023-08-02", "2023-08-03"]
    // }

    setSymptomNames(names);
    setChartSymptomName(symptomName);
    setSymptomCountName(symptomCountName);
    setSymptomSeverityName(symptomSeverityName)
    setChartData(chartData);
  }, [inputAggregated, chartSymptomName]);

  return (
    <>
      {symptomNames && symptomNames.length > 0 && (
        <Select
          labelId="symptom-selector"
          id="symptom-selector"
          value={chartSymptomName}
          onChange={(e) => { setChartSymptomName(e.target.value) }}>
          {symptomNames.map(p => <MenuItem key={p} value={p}>{p}</MenuItem>)}
        </Select>
      )}
      <Box sx={{ml: -7}}>
        <CommonChart
          chartName={t('symptomChart')}
          chartData={chartData}
          lines={[{ name: symptomSeverityName, color: "#5280EB" }, { name: symptomCountName, color: "#82ca9d" }]} />
      </Box>
    </>
  );
}

export default SymptomsChart;
