import { Button, Tooltip } from "@mui/material";
import { useUser } from "./loader/user/UserLoader";
import { useTranslation } from 'react-i18next';
import { isUserPremium } from "src/services/auth-service";

function PremiumButton({
    label,
    onClick
}) {
    const { t } = useTranslation();
    const { user } = useUser();

    return (
        isUserPremium(user) ?
            <Button
                color="success"
                onClick={onClick}
                type="submit"
                variant='contained'
                fullWidth>
                {label}
            </Button> :
            (<Tooltip title={t('availableInPremium')} placement="top-start">
                <Button
                    onClick={() => alert(t('availableInPremium'))}
                    type="submit"
                    color="black"
                    variant="outlined"
                    fullWidth>{label}</Button>
            </Tooltip>)
    )
}

export default PremiumButton;