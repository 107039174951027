import { TextField } from '@mui/material';

function NumberInput({ label, number, updateNumber }) {
  return (
    <TextField
      label={label}
      value={number}
      type="number"
      onChange={(newValue) => updateNumber(newValue.target.value)}
      fullWidth
    />
  );
}

export default NumberInput;
