import { Stack, Typography, Box } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PsychologyIcon from '@mui/icons-material/Psychology';
import WarningIcon from '@mui/icons-material/Warning';
import { useTranslation } from 'react-i18next';

function SymptomsFavouritesTutorial() {
  const { t } = useTranslation();

  return (
    <>
      <Stack direction="column" flexWrap="wrap">
        <Box sx={{display:'flex'}}>
          <FavoriteIcon sx={{ color: '#5280EB' }} />
          <Typography>
             - {t('forFrequentSymptoms')}
          </Typography>
        </Box>
        <Box sx={{display:'flex'}}>
          <PsychologyIcon sx={{ color: '#5280EB' }} />
          <Typography>
             - {t('isProcessedByML')}
          </Typography>
        </Box>
        <Box sx={{display:'flex'}}>
          <WarningIcon sx={{ color: '#1B2A4D' }} />
          <Typography>
            - {t('willNotBeProcessedByML')}
          </Typography>
        </Box>
      </Stack>
    </>
  );
}

export default SymptomsFavouritesTutorial;
