import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, TextField } from '@mui/material';
import { toDateFormat, toDateTimeFormat } from 'src/utils/datetime-utils';
import NumberInput from 'src/components/input/NumberInput';
import CommonDiaryEntryForm from '../CommonDiaryEntryForm';
import { generateGuid } from 'src/utils/common-utils';
import FavouritesTagsInput from 'src/components/input/FavouritesTagsInput';
import { useTranslation } from 'react-i18next';

function ActivityForm({ title, submitText, onSubmit, defaultValues }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [activities, setActivities] = useState(defaultValues.activities);
  const [dateTime, setDateTime] = useState(defaultValues.dateTime);
  const [minutesDuration, setMinutesDuration] = useState(defaultValues.minutesDuration);
  const [hoursDuration, setHoursDuration] = useState(defaultValues.hoursDuration);
  const [notes, setNotes] = useState(defaultValues.notes);
  const [tags, setTags] = useState(defaultValues.tags);

  useEffect(() => {
    setActivities(defaultValues.activities);
    setDateTime(defaultValues.dateTime);
    setMinutesDuration(defaultValues.minutesDuration);
    setHoursDuration(defaultValues.hoursDuration);
    setNotes(defaultValues.notes);
    setTags(defaultValues.tags);
  }, [defaultValues]);

  const handleCanceled = () => navigate(-1);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!activities || activities.length === 0) {
      alert(t('activity.fillInCorrectActivities'));
      return;
    }

    if (isNaN(hoursDuration)) {
      alert(t('fillInCorrectHours'));
      return;
    }

    if (isNaN(minutesDuration)) {
      alert(t('fillInCorrectMinutes'));
      return;
    }

    const entry = {
      // it will be overriden later for the edit page, so we will not generate new entry from edit page
      id: generateGuid(),
      entryType: 'activity',
      date: toDateFormat(dateTime),
      dateTime: toDateTimeFormat(dateTime),
      activities: activities,
      hoursDuration: hoursDuration,
      minutesDuration: minutesDuration,
      tags: tags,
      notes: notes ?? ''
    };

    onSubmit(entry);
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <CommonDiaryEntryForm
        dateTime={dateTime}
        handleCanceled={handleCanceled}
        setDateTime={setDateTime}
        title={title}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FavouritesTagsInput
                label={t('activity.activitiesLabel')}
                tags={activities ?? []}
                favouriteType="activities"
                updateTags={setActivities}
              />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
          </Grid>
        </Grid>

        <Grid item xs={6} sm={6}>
          <NumberInput
            label={t('hoursDuration')}
            number={hoursDuration}
            updateNumber={setHoursDuration}
          />
        </Grid>

        <Grid item xs={6} sm={6}>
          <NumberInput
            label={t('minutesDuration')}
            number={minutesDuration}
            updateNumber={setMinutesDuration}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label={t('notes')}
            multiline
            rows={4}
            value={notes}
            onChange={(newValue) => setNotes(newValue.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <FavouritesTagsInput
            label={t('tagsLabel')}
            tags={tags ?? []}
            favouriteType="tags"
            updateTags={setTags}
          />
        </Grid>

        <Grid item xs={12}>
          <Button type="submit" variant="contained">
            {submitText}
          </Button>
          <Button sx={{ ml: 2 }} onClick={handleCanceled}>
            {t('cancel')}
          </Button>
        </Grid>
      </CommonDiaryEntryForm>
    </form>
  );
}

export default ActivityForm;
