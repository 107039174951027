import { useEffect, useState } from 'react';
import { getFavourites, upsertFavourites } from 'src/services/firestore-storage';
import {
  FavouritesContext,
  calculateNewFavourite
} from 'src/components/loader/favourites/FavouritesContext';
import { useUser } from '../user/UserLoader';

function FavouritesLoader({ children }) {
  // Favourites is just a big object that we store to data store.
  // Item is considered to be in favourite if it is in the keys of the object.
  // It has following structure:
  // {
  //   "tags": {
  //     "tag1":"tag1"
  //   },
  //   "drinks": {
  //     "drink1": "drink1"
  //   }
  // }
  const [favourites, setFavourites] = useState({});
  const { user } = useUser();

  useEffect(() => {
    async function loadFavourites() {
      // this was added because after adding favourite we end up with infinite rerender of this component and exhasting read limit
      if (favourites && Object.keys(favourites).length > 0) {
        return;
      }

      const obj = await getFavourites(user?.id);
      
      setFavourites(obj);
    }

    loadFavourites();
  }, []);

  async function updateFavourites(favouriteType, item) {
    if (!user) {
      console.log('[updateFavourites] user id is empty');
      return;
    }

    const newFavourites = calculateNewFavourite(favourites, favouriteType, item);

    setFavourites(newFavourites);
    await upsertFavourites(user.id, newFavourites);
  }

  return (
    <FavouritesContext.Provider value={{ favourites, updateFavourites }}>
      {children}
    </FavouritesContext.Provider>
  );
}

export default FavouritesLoader;
