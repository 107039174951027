import { v4 as uuidv4 } from 'uuid';
const { useMediaQuery } = require('@mui/material');

export const isMdScreen = () => useMediaQuery('(min-width:650px)');
export const isSmScreen = () => useMediaQuery('(min-width:350px)');
export const isXsScreen = () => useMediaQuery('(min-width:150px)');

export function generateGuid() {
  return uuidv4();
}

export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}