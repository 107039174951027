import { getCurrentDate, toDateFormat, toDateTimeFormat } from 'src/utils/datetime-utils';

export const getMockedDiaryItems = (lang = 'en') => {
  if (lang === 'uk') {
    return mockedDiaryItemsUk;
  }

  return mockedDiaryItemsEn;
}

export const mockedDiaryItemsEn = [
  {
    mocked: true,
    date: toDateFormat(getCurrentDate()),
    dateTime: toDateTimeFormat(getCurrentDate()),
    notes: 'This is sample data - it will be dropped once you add new entry!',
    medications: ['esomeprazole'],
    tags: ['medication', 'stomach-med'],
    id: '58399168-aac0-4eaa-b7eb-02b497d21971',
    entryType: 'medication'
  },
  {
    mocked: true,
    date: toDateFormat(getCurrentDate()),
    dateTime: toDateTimeFormat(getCurrentDate()),
    severity: 7,
    notes: 'This is sample data - it will be dropped once you add new entry!',
    minutesDuration: '',
    symptoms: ['dizziness'],
    tags: ['symptom', 'your-tag'],
    hoursDuration: '2',
    id: 'a26bf103-f11c-482b-ba6e-56422e12bdc6',
    entryType: 'symptom'
  },
  {
    mocked: true,
    severity: 5,
    date: toDateFormat(getCurrentDate()),
    dateTime: toDateTimeFormat(getCurrentDate()),
    notes: 'This is sample data - it will be dropped once you add new entry!',
    minutesDuration: '30',
    pains: ['stomach pain'],
    tags: ['pain', 'your-tag'],
    hoursDuration: '1',
    id: '233376fb-414d-44b0-8f64-71a200961c35',
    entryType: 'pain',
    painTypes: ['burning', 'sharp']
  }
];

export const mockedDiaryItemsUk = [
  {
    mocked: true,
    date: toDateFormat(getCurrentDate()),
    dateTime: toDateTimeFormat(getCurrentDate()),
    notes: 'Цей запис слугує як приклад, його буде видалено, як тільки Ви додасте справжній!',
    medications: ['езомепразол'],
    tags: ['ліки', 'ліки-для-шлунку'],
    id: '58399168-aac0-4eaa-b7eb-02b497d21971',
    entryType: 'medication'
  },
  {
    mocked: true,
    date: toDateFormat(getCurrentDate()),
    dateTime: toDateTimeFormat(getCurrentDate()),
    severity: 7,
    notes: 'Цей запис слугує як приклад, його буде видалено, як тільки Ви додасте справжній!',
    minutesDuration: '',
    symptoms: ['запаморочення'],
    tags: ['симптом', 'ваш-тег'],
    hoursDuration: '2',
    id: 'a26bf103-f11c-482b-ba6e-56422e12bdc6',
    entryType: 'symptom'
  },
  {
    mocked: true,
    severity: 5,
    date: toDateFormat(getCurrentDate()),
    dateTime: toDateTimeFormat(getCurrentDate()),
    notes: 'Цей запис слугує як приклад, його буде видалено, як тільки Ви додасте справжній!',
    minutesDuration: '30',
    pains: ['біль у шлунку'],
    tags: ['біль', 'ваш-тег'],
    hoursDuration: '1',
    id: '233376fb-414d-44b0-8f64-71a200961c35',
    entryType: 'pain',
    painTypes: ['пекучий', 'гострий']
  }
];
