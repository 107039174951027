import LanguageSelector from "./layout/LanguageSelector";
import { useTranslation } from 'react-i18next';
import { useUser } from "./loader/user/UserLoader";
import { setUser } from "src/services/auth-service";
import { upsertUserProfile } from "src/services/firestore-storage";

function AuthenticatedLanguageSelector() {
    const { i18n } = useTranslation();
    const { user } = useUser();

    const handleChangeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('appLanguage', lang);

        updateUserlang(lang);
    };

    const updateUserlang = async (lang) => {
        // this is needed because on the login screen we don't have user
        if (user) {
            user.lang = lang;

            setUser(user);
            await upsertUserProfile(user.id, user);
        }
    }

    return (
        <LanguageSelector onLanguageChange={handleChangeLanguage} />
    )
}

export default AuthenticatedLanguageSelector;