import { useEffect, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, Select } from '@mui/material';
import CommonChart from './CommonChart';

function FoodChart({ inputAggregated }) {
  const { t, i18n } = useTranslation();

  const [foodCountName, setFoodCountName] = useState('');
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const foodsMap = inputAggregated?.loadedFoods?.foodsMap;
    const datesRange = inputAggregated?.datesRange;

    if (!foodsMap || !datesRange) {
      setChartData([]);
      setFoodCountName('');
      return;
    }

    const foodCountChartObj = {};

    for (let date of datesRange) {
      foodCountChartObj['food'] ??= [];

      const foodsOnDate = foodsMap[date];

      let painCount = 0;

      if (foodsOnDate) {
        painCount = foodsOnDate.length;
      }

      foodCountChartObj['food'].push(painCount);
    }

    const chartData = [];
    const foodCountName = `${t('food.foodTag')} ${t('count')}`;

    for (let i = 0; i < datesRange.length; i++) {
      let item = {
        name: datesRange[i]
      };

      item[foodCountName] = foodCountChartObj['food'][i];

      chartData.push(item);
    }

    setFoodCountName(foodCountName);
    setChartData(chartData);
  }, [inputAggregated]);

  return (
    <>
      <Select
        labelId="food-selector"
        id="food-selector"
        value={t('food.foodTag')}
        disabled>
          <MenuItem value={t('food.foodTag')}>{t('food.foodTag')}</MenuItem>
      </Select>

      <Box sx={{ ml: -7 }}>
        <CommonChart
          chartName={t('foodChart')}
          chartData={chartData}
          lines={[{ name: foodCountName, color: "#82ca9d" }]} />
      </Box>
    </>
  );
}

export default FoodChart;
