import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getDiaryEntryById } from 'src/services/firestore-storage';
import BowelMovementForm from 'src/pages/diary/bowel-movement/BowelMovementForm';
import { fromDateTimeFormat, getDateTimeFromQueryOrNow } from 'src/utils/datetime-utils';
import { useEditEntry } from 'src/utils/diary-form-utils';
import { t } from 'i18next';
import { useUser } from 'src/components/loader/user/UserLoader';

function EditBowelMovement() {
  const date = getDateTimeFromQueryOrNow();
  const { id } = useParams();
  const { user } = useUser();

  const [oldTags, setOldTags] = useState([]);
  const [defaultBowelMovements, setDefaultBowelMovements] = useState({
    entryType: 'bowelMovement',
    dateTime: fromDateTimeFormat(date),
    bristolMark: 4,
    notes: '',
    tags: []
  });

  useEffect(() => {
    async function load() {
      const entry = await getDiaryEntryById(user.id, id);

      setOldTags(entry.tags);

      setDefaultBowelMovements({
        entryType: 'bowelMovement',
        dateTime: fromDateTimeFormat(entry.dateTime),
        bristolMark: entry.bristolMark,
        notes: entry.notes,
        tags: entry.tags
      });
    }

    load();
  }, [id, date]);

  const onSubmit = useEditEntry(id, oldTags);

  return (
    <BowelMovementForm
      title={t('bowelMovement.editBowelMovement')}
      submitText={t('edit')}
      onSubmit={onSubmit}
      defaultValues={defaultBowelMovements}
    />
  );
}

export default EditBowelMovement;
