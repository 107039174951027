import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import ChipList from 'src/components/ChipList';
import CommonDiaryItem from '../CommonDiaryItem';
import { useTranslation } from 'react-i18next';

function FoodItem({ item, handleDelete, editRoute }) {
  const { t } = useTranslation();

  return (
    <CommonDiaryItem item={item} handleDelete={handleDelete} editRoute={editRoute}>
      <Stack
        spacing={{ xs: 1, sm: 2 }}
        direction="row"
        justifyContent="space-between"
        useFlexGap
        flexWrap="wrap">
        <Typography variant="h6" color="#5280EB">
          <img style={{ verticalAlign: 'sub' }} src="/restaurant.svg" /> {t('food.food')}
        </Typography>

        <Typography variant="body2">{item?.dateTime}</Typography>
      </Stack>

      <Divider></Divider>

      <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
        <ChipList
          color="lightSuccess"
          label={`${t('food.main')}${item?.mainGrams ? `(${item?.mainGrams} ${t('common.g')})` : ''}`}
          bold={false}
          tags={item?.main}
        />
      </Stack>

      <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
        <ChipList
          color="lightSuccess"
          label={`${t('food.drinks')}${item?.drinksMilliliters ? `(${item?.drinksMilliliters} ${t('common.ml')})` : ''}`}
          bold={false}
          tags={item?.drinks}
        />
      </Stack>

      <ChipList color="lightSuccess" label={t('food.sweets')} bold={false} tags={item?.sweets} />

      {/* <ChipList color='lightSuccess' label="Fruits" bold={false} tags={item?.fruits} /> */}
    </CommonDiaryItem>
  );
}

export default FoodItem;
