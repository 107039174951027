import { addTagToMap, calculateNewTagsEntryFromUpdate } from 'src/domain/tags-service';
import { getCurrentUser } from 'src/services/auth-service';
import {
  getTagsMap,
  upsertDiary,
  upsertTagsMap,
  upsertUserProfile
} from 'src/services/firestore-storage';
import { useNavigate } from 'react-router-dom';

export const useCreateEntry = () => {
  const navigate = useNavigate();

  const onSubmit = async (entry) => {
    const currentUser = await getCurrentUser();
    await upsertDiary(entry.id, entry, currentUser.id);

    const tagsEntry = (await getTagsMap(currentUser.id)) ?? {};

    for (let tag of entry.tags) {
      addTagToMap(tagsEntry, tag);
    }

    await upsertTagsMap(currentUser.id, tagsEntry);

    if (currentUser.isNewAccount) {
      currentUser.isNewAccount = false;
      upsertUserProfile(currentUser.id, currentUser);
    }

    navigate('/diary');
  };

  return onSubmit;
};

export const useEditEntry = (id, oldTags) => {
  const navigate = useNavigate();

  const onSubmit = async (entry) => {
    const currentUser = await getCurrentUser();
    entry.id = id;

    await upsertDiary(entry.id, entry, currentUser.id);

    const tagsEntry = await getTagsMap(currentUser.id);
    calculateNewTagsEntryFromUpdate(tagsEntry, oldTags, entry.tags, entry.id);

    await upsertTagsMap(currentUser.id, tagsEntry);

    navigate('/diary');
  };

  return onSubmit;
};
