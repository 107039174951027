import { useContext, useEffect, useState } from 'react';
import { FavouritesContext } from 'src/components/loader/favourites/FavouritesContext';
import TagsInput from './TagsInput';

function SymptomsTagsInput({ label, tags, favouriteType, updateTags, limit = 100, aiItems = [] }) {
  const [favouriteItems, setFavouriteItems] = useState([]);
  const [itemsMetadata, setItemsMetadata] = useState({
    favourites: new Set(),
    aiItems: new Set(),
    warningItems: new Set()
  });

  const { favourites, updateFavourites } = useContext(FavouritesContext);

  useEffect(() => {
    let localItemsMetadata = {
      favourites: new Set(),
      aiItems: new Set(),
      warningItems: new Set()
    };

    let localFavourites = [];

    if (favourites && favourites[favouriteType]) {
      localFavourites = Object.keys(favourites[favouriteType]);
      localItemsMetadata.favourites = new Set(localFavourites);
    }

    let localAiItems = [];

    if (aiItems && aiItems.length > 0) {
      localAiItems = [...aiItems];
      localItemsMetadata.aiItems = new Set(aiItems);
    }

    for (let item of localFavourites) {
      if (!localItemsMetadata.aiItems.has(item)) {
        localItemsMetadata.warningItems.add(item);
      }
    }

    let resultingItems = [...localFavourites];

    for (let item of localAiItems) {
      if (!localItemsMetadata.favourites.has(item)) {
        resultingItems.push(item);
      }
    }

    setItemsMetadata(localItemsMetadata);
    setFavouriteItems(resultingItems);
  }, [favourites, aiItems]);

  return (
    <>
      <TagsInput
        label={label}
        tags={tags}
        updateTags={updateTags}
        favouriteItems={favouriteItems}
        updateFavourites={(i) => {
          updateFavourites(favouriteType, i);
        }}
        limit={limit}
        itemsMetadata={itemsMetadata}
      />
    </>
  );
}

export default SymptomsTagsInput;
