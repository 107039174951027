import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getDiaryEntryById } from 'src/services/firestore-storage';
import ActivityForm from 'src/pages/diary/activity/ActivityForm';
import { fromDateTimeFormat, getDateTimeFromQueryOrNow } from 'src/utils/datetime-utils';
import { useEditEntry } from 'src/utils/diary-form-utils';
import { useTranslation } from 'react-i18next';
import { useUser } from 'src/components/loader/user/UserLoader';

function EditActivity() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = useUser();

  // we use here string otherwise it will rerender because of dependency in useEffect
  const date = getDateTimeFromQueryOrNow();

  const [oldTags, setOldTags] = useState([]);
  const [defaultActivity, setDefaultActivity] = useState({
    entryType: 'activity',
    dateTime: fromDateTimeFormat(date),
    hoursDuration: '',
    minutesDuration: '',
    activities: [],
    notes: '',
    tags: []
  });

  useEffect(() => {
    async function load() {
      const entry = await getDiaryEntryById(user.id, id);

      setOldTags(entry.tags);

      setDefaultActivity({
        entryType: 'activity',
        dateTime: fromDateTimeFormat(entry.dateTime),
        hoursDuration: entry.hoursDuration,
        minutesDuration: entry.minutesDuration,
        activities: entry.activities,
        notes: entry.notes,
        tags: entry.tags
      });
    }

    load();
  }, [id, date]);

  const onSubmit = useEditEntry(id, oldTags);

  return (
    <ActivityForm
      title={t('activity.editActivity')}
      submitText={t('edit')}
      onSubmit={onSubmit}
      defaultValues={defaultActivity}
    />
  );
}

export default EditActivity;
