import React from 'react';
import DiaryPage from 'src/pages/diary/diary.page';
import CreateSymptom from 'src/pages/diary/symptom/create-symptom.page';
import EditSymptom from 'src/pages/diary/symptom/edit-symptom.page';
import CreateFood from 'src/pages/diary/food/create-food.page';
import EditFood from 'src/pages/diary/food/edit-food.page';
import CreateActivity from 'src/pages/diary/activity/create-activity.page';
import EditActivity from 'src/pages/diary/activity/edit-activity.page';
import EditBowelMovement from 'src/pages/diary/bowel-movement/edit-bowel-movement.page';
import CreateBowelMovement from 'src/pages/diary/bowel-movement/create-bowel-movement.page';
import ReportsPage from 'src/pages/reports-tab/reports.page';
import CreatePain from 'src/pages/diary/pain/create-pain.page';
import EditPain from 'src/pages/diary/pain/edit-pain.page';
import SearchPage from 'src/pages/search/search.page';
import { PrivacyPolicy } from 'src/pages/privacy-policy.page';
import { TermsOfUse } from 'src/pages/terms-of-use.page';
import PricingPage from 'src/pages/pricing/pricing.page';
import CreateMedication from 'src/pages/diary/medication/create-medication.page';
import EditMedication from 'src/pages/diary/medication/edit-medication.page';
import CreateWeight from 'src/pages/diary/weight/create-weight.page';
import EditWeightItem from 'src/pages/diary/weight/edit-weight.page';
import ProfilePage from './pages/profile/profile.page';

export const authRoutes = [
  {
    path: '/terms-of-use',
    redirectPath: '/',
    content: () => <TermsOfUse />
  },
  {
    path: '/pricing',
    redirectPath: '/',
    content: () => <PricingPage />
  },
  {
    path: '/privacy-policy',
    redirectPath: '/',
    content: () => <PrivacyPolicy />
  },
  {
    path: '/profile',
    redirectPath: '/',
    content: () => <ProfilePage />
  },
  {
    path: '/search',
    redirectPath: '/',
    content: () => (
        <SearchPage />
    )
  },
  {
    path: '/reports',
    redirectPath: '/',
    content: () => (
        <ReportsPage />
    )
  },
  {
    path: '/diary',
    redirectPath: '/',
    content: () => (
        <DiaryPage />
    )
  },
  {
    path: '/diary/create-weight',
    redirectPath: '/',
    content: () => (
        <CreateWeight />
    )
  },
  {
    path: '/diary/edit-weight/:id',
    redirectPath: '/',
    content: () => (
        <EditWeightItem />
    )
  },
  {
    path: '/diary/create-medication',
    redirectPath: '/',
    content: () => (
        <CreateMedication />
    )
  },
  {
    path: '/diary/edit-medication/:id',
    redirectPath: '/',
    content: () => (
        <EditMedication />
    )
  },
  {
    path: '/diary/create-symptom',
    redirectPath: '/',
    content: () => (
        <CreateSymptom />
    )
  },
  {
    path: '/diary/edit-symptom/:id',
    redirectPath: '/',
    content: () => (
        <EditSymptom />
    )
  },
  {
    path: '/diary/create-pain',
    redirectPath: '/',
    content: () => (
        <CreatePain />
    )
  },
  {
    path: '/diary/edit-pain/:id',
    redirectPath: '/',
    content: () => (
        <EditPain />
    )
  },
  {
    path: '/diary/create-food',
    redirectPath: '/',
    content: () => (
        <CreateFood />
    )
  },
  {
    path: '/diary/edit-food/:id',
    redirectPath: '/',
    content: () => (
        <EditFood />
    )
  },
  {
    path: '/diary/create-activity',
    redirectPath: '/',
    content: () => (
        <CreateActivity />
    )
  },
  {
    path: '/diary/edit-activity/:id',
    redirectPath: '/',
    content: () => (
        <EditActivity />
    )
  },
  {
    path: '/diary/create-bowelMovement',
    redirectPath: '/',
    content: () => (
        <CreateBowelMovement />
    )
  },
  {
    path: '/diary/edit-bowelMovement/:id',
    redirectPath: '/',
    content: () => (
        <EditBowelMovement />
    )
  }
];
