import { Box, Container, CssBaseline } from '@mui/material';
import Footer from 'src/components/layout/Footer';
import Header from 'src/components/layout/Header';
import UnAuthenticated from '../../pages/auth/unauthenticated.page';
import Seo from '../Seo';

function Layout({ authenticated, children, isRendering }) {
  if (!authenticated) {
    return <></>;
  }

  return (
    <>
      <Seo />
      <Box height="100vh" display="flex" flexDirection="column">
        <CssBaseline />
        <Header authenticated={authenticated} />
        <Container sx={{ maxWidth: '800px', pt: 2, pb: 8 }}>
          {isRendering ? <UnAuthenticated authenticated={authenticated} /> : children}
        </Container>

        <Footer />
      </Box>
    </>
  );
}

export default Layout;
