import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, TextField } from '@mui/material';
import { toDateFormat, toDateTimeFormat } from 'src/utils/datetime-utils';
import CommonDiaryEntryForm from '../CommonDiaryEntryForm';
import { generateGuid } from 'src/utils/common-utils';
import FavouritesTagsInput from 'src/components/input/FavouritesTagsInput';
import { useTranslation } from 'react-i18next';

function MedicationForm({ title, submitText, onSubmit, defaultValues }) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [medications, setMedications] = useState(defaultValues?.medications);
  const [dateTime, setDateTime] = useState(defaultValues.dateTime);
  const [notes, setNotes] = useState(defaultValues.notes);
  const [tags, setTags] = useState(defaultValues.tags);

  useEffect(() => {
    setMedications(defaultValues.medications);
    setDateTime(defaultValues.dateTime);
    setNotes(defaultValues.notes);
    setTags(defaultValues.tags);
  }, [defaultValues]);

  const handleCanceled = () => navigate(-1);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!medications || medications.length === 0) {
      alert(t('medication.medicationValidationMessage'));
      return;
    }

    const entry = {
      // it will be overriden later for the edit page, so we will not generate new entry from edit page
      id: generateGuid(),
      entryType: 'medication',
      date: toDateFormat(dateTime),
      dateTime: toDateTimeFormat(dateTime),
      medications: medications,
      tags: tags,
      notes: notes ?? ''
    };

    if (!entry.medications) {
      return;
    }

    onSubmit(entry);
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <CommonDiaryEntryForm
        dateTime={dateTime}
        handleCanceled={handleCanceled}
        setDateTime={setDateTime}
        title={title}>
        <Grid item xs={12} sm={6}>
          <FavouritesTagsInput
            label={t('medication.medicationNameLabel')}
            tags={medications ?? []}
            favouriteType="medications"
            updateTags={(medications) => setMedications(medications)}
            limit={1}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label={t('notesLabel')}
            multiline
            rows={4}
            value={notes}
            onChange={(newValue) => setNotes(newValue.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <FavouritesTagsInput
            label={t('tagsLabel')}
            tags={tags ?? []}
            favouriteType="tags"
            updateTags={setTags}
          />
        </Grid>

        <Grid item xs={12}>
          <Button type="submit" variant="contained">
            {submitText}
          </Button>
          <Button sx={{ ml: 2 }} onClick={handleCanceled}>
            {t('cancel')}
          </Button>
        </Grid>
      </CommonDiaryEntryForm>
    </form>
  );
}

export default MedicationForm;
