import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import CommonDiaryItem from '../CommonDiaryItem';
import { useTranslation } from 'react-i18next';

function BowelMovementItem({ item, handleDelete, editRoute }) {
  const { t } = useTranslation();

  return (
    <CommonDiaryItem item={item} handleDelete={handleDelete} editRoute={editRoute}>
      <Stack
        spacing={{ xs: 1, sm: 2 }}
        direction="row"
        justifyContent="space-between"
        useFlexGap
        flexWrap="wrap">
        <Typography variant="h6" color="#5280EB">
          <img style={{ verticalAlign: 'sub' }} src="/wc.svg" /> {t('bowelMovement.bowelMovement')}
        </Typography>

        <Typography variant="body2">{item?.dateTime}</Typography>
      </Stack>

      <Divider></Divider>

      <Stack
        spacing={{ xs: 1, sm: 2 }}
        direction="row"
        justifyContent="start"
        useFlexGap
        flexWrap="wrap"
        sx={{ mt: '5px' }}>
        <Typography>{t('bristolScale')}: {item?.bristolMark}</Typography>
      </Stack>
    </CommonDiaryItem>
  );
}

export default BowelMovementItem;
