import React, { useState } from 'react';
import { fromDateTimeFormat, getDateTimeFromQueryOrNow } from 'src/utils/datetime-utils';
import WeightForm from './WeightForm';
import { useCreateEntry } from 'src/utils/diary-form-utils';
import { useTranslation } from 'react-i18next';

function CreateWeight() {
  const { t } = useTranslation();
  const date = getDateTimeFromQueryOrNow();

  const [defaultBowelMovements] = useState({
    entryType: 'weight',
    dateTime: fromDateTimeFormat(date),
    weight: 50,
    notes: '',
    tags: [t('weight.weightTag')]
  });

  const onSubmit = useCreateEntry();

  return (
    <WeightForm
      title={t('weight.addWeight')}
      submitText={t('create')}
      onSubmit={onSubmit}
      defaultValues={defaultBowelMovements}
    />
  );
}

export default CreateWeight;
