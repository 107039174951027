import { useEffect, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, Select } from '@mui/material';
import CommonChart from './CommonChart';

function BowelMovementChart({ inputAggregated }) {
  const { t, i18n } = useTranslation();

  const [bowelMovementCountName, setBowelMovementCountName] = useState('');
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const bowelMovementsMap = inputAggregated?.loadedBowelMovements?.bowelMovementsMap;
    const datesRange = inputAggregated?.datesRange;

    if (!bowelMovementsMap || !datesRange) {
      setChartData([]);
      setBowelMovementCountName('');
      return;
    }

    const bowelMovementCountChartObj = {};

    for (let date of datesRange) {
      bowelMovementCountChartObj['bowelMovement'] ??= [];
      const bowelMovementsOnDate = bowelMovementsMap[date];

      let bowelMovementCount = 0;

      if (bowelMovementsOnDate) {
        bowelMovementCount = bowelMovementsOnDate.length;
      }

      bowelMovementCountChartObj['bowelMovement'].push(bowelMovementCount);
    }

    const chartData = [];
    const bowelMovementCountName = `${t('bowelMovement.bowelMovementTag')} ${t('count')}`;

    for (let i = 0; i < datesRange.length; i++) {
      let item = {
        name: datesRange[i]
      };

      item[bowelMovementCountName] = bowelMovementCountChartObj['bowelMovement'][i];

      chartData.push(item);
    }

    setBowelMovementCountName(bowelMovementCountName);
    setChartData(chartData);
  }, [inputAggregated]);

  return (
    <>
      <Select
        labelId="bowelMovement-selector"
        id="bowelMovement-selector"
        value={t('bowelMovement.bowelMovementTag')}
        disabled>
          <MenuItem value={t('bowelMovement.bowelMovementTag')}>{t('bowelMovement.bowelMovementTag')}</MenuItem>
      </Select>

      <Box sx={{ ml: -7 }}>
        <CommonChart
          chartName={t('bowelMovementChart')}
          chartData={chartData}
          lines={[{ name: bowelMovementCountName, color: "#82ca9d" }]} />
      </Box>
    </>
  );
}

export default BowelMovementChart;
