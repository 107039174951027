import {
  AppBar as MuiAppBar,
  Box,
  Toolbar,
  Typography,
  Container,
  Button,
  IconButton
} from '@mui/material';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import { useNavigate } from 'react-router-dom';
import { logOut } from 'src/services/auth-service';
import { googleLogout } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import AuthenticatedLanguageSelector from '../AuthenticatedLanguageSelector';

const logoText = 'Symptom Diary';

function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logOut();
      googleLogout();
    } catch {
      console.log('logout error');
    }
    navigate('/');
  };

  return (
    <>
      <MuiAppBar position="static">
        <Container sx={{ maxWidth: '800px' }}>
          <Toolbar disableGutters>
            <MonitorHeartIcon onClick={() => navigate('/diary')} sx={{ display: { xs: 'flex' }, mr: 1 }} />
            <Button
              onClick={() => navigate('/diary')}
              sx={{
                mr: 2,
                display: { xs: 'none', sm: 'flex' },
                fontWeight: 700,
                color: 'inherit',
                textDecoration: 'none'
              }}>
              <Typography variant="h6" noWrap component="a">
                {t(logoText)}
              </Typography>
            </Button>

            <Button
              onClick={() => navigate('/pricing')}
              sx={{
                color: 'inherit'
              }}>
              {t('pricing')}
            </Button>

            <Box sx={{ flexGrow: 1, display: 'flex' }} />

            <Box sx={{ mr: 2 }}>
              <AuthenticatedLanguageSelector />
            </Box>

            <IconButton
              onClick={() => navigate('/profile')}
              sx={{
                verticalAlign: 'sub',
                color: 'white',
                width: '40px',
                height: '40px',
                '& path': { transition: 'fill .5s', fill: '#ededed' },
                '&:hover': { path: { fill: '#fff' } }
              }}>
              <PersonIcon />
            </IconButton>

            <IconButton
              onClick={handleLogout}
              sx={{
                verticalAlign: 'sub',
                color: 'white',
                width: '40px',
                height: '40px',
                '& path': { transition: 'fill .5s', fill: '#ededed' },
                '&:hover': { path: { fill: '#fff' } }
              }}>
              <LogoutIcon />
            </IconButton>
          </Toolbar>
        </Container>
      </MuiAppBar>
    </>
  );
}

export default Header;
