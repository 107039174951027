import { Chip, Stack, Typography } from '@mui/material';

function ChipList({ label, tags, bold, color = 'primary' }) {
  return (
    <Stack spacing={{ xs: 1 }} direction="row" flexWrap="wrap" sx={{ mt: '5px' }}>
      {tags && tags?.length > 0 && (
        <>
          {bold ? (
            <Typography>
              <b>{label}</b>
            </Typography>
          ) : (
            <Typography>{label}</Typography>
          )}
          :{' '}
          {tags.map((t) => (
            <Chip
              sx={{ mr: 1 }}
              color={color}
              id={`chip1${t}`}
              key={`chip1${t}`}
              variant="outlined"
              label={t}
            />
          ))}
        </>
      )}
    </Stack>
  );
}

export default ChipList;
