import { Grid, Box } from '@mui/material';
import PlanCard from './Plan';
import { useEffect, useState } from 'react';
import { getUserRole } from 'src/services/auth-service';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useUser } from 'src/components/loader/user/UserLoader';

// TODO Add medication, etc entries to charts and summary
function PricingPage() {
  const { t } = useTranslation();
  const { user } = useUser();

  const definedPlans = [
    {
      id: 'basic',
      name: t('price.basicPlan'),
      features: [
        t('price.basicEntriesFeature'),
        t('price.basicReportsFeature'),
        t('price.basicDateRangeFeature'),
        t('price.basicSearchFeature'),
        t('price.basicExportFeature')
      ],
      price: null,
      role: 'basic'
    },
    {
      id: 'premium',
      name: t('price.premiumPlan'),
      features: [
        t('price.firstMonthIsFree'),
        t('price.premiumBasicPlanFeatures'),
        t('price.premiumEntriesFeature'),
        t('price.premiumSummaryFeature'),
        t('price.premiumDateRangesFeature'),
        t('price.premiumTelegramNotifications'),
        t('price.premiumExportFeature'),
        t('price.premiumSupportFeature'),
        t('price.premiumSuggestFeature'),
        t('price.premiumWebHookSupport'),
      ],
      price: '4.99',
      role: 'premium',
      paymentLink: process.env.REACT_APP_PREMIUM_PAYMENT_LINK
    }
  ];

  const [plans, setPlans] = useState([]);

  useEffect(() => {
    async function fetch() {
      let copiedPlans = [...definedPlans];

      if (getUserRole(user) == 'basic') {
        let parameters = '';

        if (user) {
          parameters = `?prefilled_email=${user.email}&client_reference_id=${user.id}`;
        }

        copiedPlans.map((c) => (c.paymentLink = `${c.paymentLink}${parameters}`));
        copiedPlans.filter((c) => c.id === 'basic').map((c) => (c.disabled = true));
      } else {
        copiedPlans.map((c) => (c.disabled = true));

        for (let plan of copiedPlans) {
          if (getUserRole(user) === plan.role) {
            plan.selected = true;
            plan.disabled = false;

            let parameters = '';

            if (user) {
              parameters = `?prefilled_email=${user.email}&client_reference_id=${user.id}`;
            }

            plan.cancelLink = `${process.env.REACT_APP_CUSTOMER_PORTAL_LINK}${parameters}`;
          }
        }
      }

      setPlans(copiedPlans);
    }

    fetch();
  }, [i18next.language]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <Grid container justifyContent="center" spacing={2}>
        {plans.map((plan, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <PlanCard plan={plan} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default PricingPage;
