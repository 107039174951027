import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getDiaryEntryById } from 'src/services/firestore-storage';
import SymptomForm from 'src/pages/diary/symptom/SymptomForm';
import { fromDateTimeFormat, getDateTimeFromQueryOrNow } from 'src/utils/datetime-utils';
import { useEditEntry } from 'src/utils/diary-form-utils';
import { useTranslation } from 'react-i18next';
import { useUser } from 'src/components/loader/user/UserLoader';

function EditSymptom() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = useUser();
  const date = getDateTimeFromQueryOrNow();

  const [oldTags, setOldTags] = useState([]);

  const [defaultValues, setDefaultValues] = useState({
    entryType: 'symptom',
    symptoms: [],
    dateTime: fromDateTimeFormat(date),
    severity: 2,
    hoursDuration: '',
    minutesDuration: '',
    notes: '',
    tags: []
  });

  useEffect(() => {
    async function load() {
      const entry = await getDiaryEntryById(user.id, id);

      setOldTags(entry.tags);

      setDefaultValues({
        entryType: 'symptom',
        symptoms: entry.symptoms,
        dateTime: fromDateTimeFormat(entry.dateTime),
        severity: entry.severity,
        hoursDuration: entry.hoursDuration,
        minutesDuration: entry.minutesDuration,
        notes: entry.notes,
        tags: entry.tags
      });
    }

    load();
  }, [id, date]);

  const onSubmit = useEditEntry(id, oldTags);

  return (
    <SymptomForm
      title={t('symptom.editSymptom')}
      submitText={t('edit')}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
    />
  );
}

export default EditSymptom;
