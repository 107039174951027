import { Typography, Box } from '@mui/material';
import { Navigate } from 'react-router-dom';
import Seo from 'src/components/Seo';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import { signInWithGoogleCred } from 'src/services/auth-service';
import { useTranslation } from 'react-i18next';
import LanguageSelector from 'src/components/layout/LanguageSelector';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function UnAuthenticated({ authenticated }) {
  const { t } = useTranslation();
  const location = useLocation();
  const [defaultLang, setDefaultLang] = useState(undefined);

  useEffect(() => {
    setDefaultLang(undefined);
    const queryParams = new URLSearchParams(location.search);

    const lang = queryParams.get('lang');
    setDefaultLang(lang);
  }, []);

  if (authenticated) {
    return <Navigate to="/diary" replace />;
  }

  return (
    <>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_KEY}>
        <Seo />

        <LanguageSelector color='black' defaultLanguage={defaultLang} />

        <Box sx={{ justifyContent: 'center', alignItems: 'center', flexGrow: 1, display: 'flex' }}>
          <MonitorHeartIcon sx={{ display: { xs: 'flex' }, mr: 1 }} />
          <Typography align="center" variant="h6">
            {t('symptomDiary')}
          </Typography>
        </Box>

        <br></br>

        <Typography align="center">{t('trackYourPainSymptoms')}</Typography>
        <Typography align="center">{t('manageYourMFA')}</Typography>

        <br></br>

        <Typography variant="body2" align="center">
          <b>{t('openAppInChromeNotification')}</b>
        </Typography>

        <Box sx={{ justifyContent: 'center', alignItems: 'center', flexGrow: 1, display: 'flex' }}>
          <GoogleLogin
            onSuccess={async (credentialResponse) => {
              try {
                await signInWithGoogleCred(credentialResponse.credential);
              } catch (e) {
                const errorCode = e.code;
                const errorMessage = e.message;

                alert('Error during google auth');
                console.log(`${errorCode}, ${errorMessage}`);
              }
            }}
            onError={(e) => {
              alert(`Error during google auth`);
              console.log(e);
            }}
          />
        </Box>
      </GoogleOAuthProvider>

      {/* <br></br>

            <Box sx={{ justifyContent: "center", alignItems: "center", flexGrow: 1, display: 'flex' }}>
                <Button
                    key='register'
                    onClick={() => navigate('/register')}
                    sx={{ color: 'blue', display: 'block' }}
                >
                    Register with Email and Password
                </Button>
            </Box>

            <Box sx={{ justifyContent: "center", alignItems: "center", flexGrow: 1, display: 'flex' }}>
                <Button
                    key='loginwithEmail'
                    onClick={() => navigate('/sign-in')}
                    sx={{ color: 'blue', display: 'block' }}
                >
                    Login with Email and Password
                </Button>
            </Box> */}
    </>
  );
}

export default UnAuthenticated;
