import React, { useState } from 'react';
import FoodForm from 'src/pages/diary/food/FoodForm';
import { fromDateTimeFormat, getDateTimeFromQueryOrNow } from 'src/utils/datetime-utils';
import { useCreateEntry } from 'src/utils/diary-form-utils';
import { useTranslation } from 'react-i18next';

function CreateFood() {
  const { t } = useTranslation();
  const date = getDateTimeFromQueryOrNow();

  const [defaultValues] = useState({
    entryType: 'food',
    dateTime: fromDateTimeFormat(date),
    drinks: [],
    drinksMilliliters: '',
    main: [],
    mainGrams: '',
    sweets: [],
    // fruits: [],
    notes: '',
    tags: [t('food.foodTag')]
  });

  const onSubmit = useCreateEntry();

  return (
    <FoodForm
      title={t('food.addFood')}
      submitText={t('create')}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
    />
  );
}

export default CreateFood;
