import React, { useEffect } from 'react';
import { MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

function LanguageSelector({
  onLanguageChange,
  defaultLanguage, // this is language that will come from query parameter, it should override current lang
  color = "#FFFFFF"
}) {
  const { i18n } = useTranslation();

  useEffect(() => {
    let lang = localStorage.getItem('appLanguage');

    if (defaultLanguage) {
      lang = defaultLanguage;
    }

    if (lang && ['en', 'uk'].includes(lang)) {
      i18n.changeLanguage(lang);
      handleChangeLanguage({ target: { value: lang } });
    }
  }, [defaultLanguage]);

  const handleChangeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
    localStorage.setItem('appLanguage', event.target.value);

    onLanguageChange?.(event.target.value);
  };

  return (
    <Select
      labelId="language-select-label"
      id="language-select"
      value={i18n.language}
      onChange={handleChangeLanguage}
      sx={{
        color: color,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: color, // Border color
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: color, // Border color on hover
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: color, // Border color on focus
        },
        '& .MuiSelect-icon': {
          color: color, // Color of the arrow icon
        }
      }}>
      <MenuItem value="en">En</MenuItem>
      <MenuItem value="uk">Укр</MenuItem>
    </Select>
  );
}

export default LanguageSelector;
