import React from 'react';
import { Divider, Stack, Tooltip, Typography } from '@mui/material';
import ChipList from 'src/components/ChipList';
import CommonDiaryItem from '../CommonDiaryItem';
import { useTranslation } from 'react-i18next';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

function SymptomItem({ item, handleDelete, editRoute }) {
  const { t } = useTranslation();

  function isWholeDay() {
    return Number.parseInt(item?.hoursDuration) >= 24;
  }

  return (
    <CommonDiaryItem item={item} handleDelete={handleDelete} editRoute={editRoute}>
      <Stack
        spacing={{ xs: 1, sm: 2 }}
        direction="row"
        justifyContent="space-between"
        useFlexGap
        flexWrap="wrap">
        <Typography variant="h6" color="#5280EB">
          <img style={{ verticalAlign: 'sub' }} src="/healing.svg" /> {t('symptom.symptom')} {isWholeDay() && <Tooltip title={t('wholeDay')}><AccessTimeIcon style={{ verticalAlign: 'sub' }} /></Tooltip>}
        </Typography>

        <Typography variant="body2">{item?.dateTime}</Typography>
      </Stack>

      <Divider></Divider>

      <ChipList label={t('symptom.symptom')} tags={item?.symptoms} />

      <Stack
        spacing={{ åxs: 1, sm: 2 }}
        direction="row"
        justifyContent="start"
        useFlexGap
        flexWrap="wrap"
        sx={{ mt: '5px' }}>
        <Typography>
          {t('duration')}{' '}
          {(item?.hoursDuration ? Number.parseInt(item?.hoursDuration) : 0)?.toLocaleString(
            undefined,
            { minimumIntegerDigits: 2 }
          )}
          :
          {(item?.minutesDuration ? Number.parseInt(item?.minutesDuration) : 0)?.toLocaleString(
            undefined,
            { minimumIntegerDigits: 2 }
          )}
        </Typography>

        <Typography>{t('severity')}: {item.severity}</Typography>
      </Stack>
    </CommonDiaryItem>
  );
}

export default SymptomItem;
