import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getDiaryEntryById } from 'src/services/firestore-storage';
import FoodForm from 'src/pages/diary/food/FoodForm';
import { fromDateTimeFormat, getDateTimeFromQueryOrNow } from 'src/utils/datetime-utils';
import { useEditEntry } from 'src/utils/diary-form-utils';
import { useTranslation } from 'react-i18next';
import { useUser } from 'src/components/loader/user/UserLoader';

function EditFood() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = useUser();
  const date = getDateTimeFromQueryOrNow();

  const [oldTags, setOldTags] = useState([]);

  const [defaultValues, setDefaultValues] = useState({
    entryType: 'food',
    dateTime: fromDateTimeFormat(date),
    drinks: [],
    drinksMilliliters: '',
    main: [],
    mainGrams: '',
    sweets: [],
    fruits: [],
    notes: '',
    tags: []
  });

  useEffect(() => {
    async function load() {
      const entry = await getDiaryEntryById(user.id, id);

      setOldTags(entry.tags);

      setDefaultValues({
        entryType: 'food',
        dateTime: fromDateTimeFormat(entry.dateTime),
        drinks: entry.drinks,
        drinksMilliliters: entry.drinksMilliliters,
        main: entry.main,
        mainGrams: entry.mainGrams,
        sweets: entry.sweets,
        fruits: entry.fruits,
        notes: entry.notes,
        tags: entry.tags
      });
    }

    load();
  }, [id, date]);

  const onSubmit = useEditEntry(id, oldTags);

  return (
    <FoodForm
      title={t('food.editFood')}
      submitText={t('edit')}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
    />
  );
}

export default EditFood;
