export const aggregateItems = (items, datesRange) => {
  const loadedPains = {
    count: 0
  };

  const loadedSymptoms = {
    count: 0
  };

  const loadedFoods = {
    count: 0
  };
  const loadedBowelMovements = {
    count: 0
  };

  const loadedActivities = {
    count: 0
  };

  const loadedMedications = {
    count: 0
  };

  const tagsMap = new Map();

  for (let item of items) {
    if (item.entryType === 'pain') {
      addPainItem(loadedPains, item);
    }

    if (item.entryType === 'symptom') {
      addSymptomItem(loadedSymptoms, item);
    }

    if (item.entryType === 'food') {
      addFoodItem(loadedFoods, item);
    }

    if (item.entryType === 'bowelMovement') {
      addBowelMovementItem(loadedBowelMovements, item);
    }

    if (item.entryType === 'activity') {
      addActivityItem(loadedActivities, item);
    }

    if (item.entryType === 'medication') {
      addMedicationItem(loadedMedications, item);
    }

    if (item.tags && item.tags.length > 0) {
      for (let tag of item.tags) {
        if (!tagsMap.has(tag)) {
          tagsMap.set(tag, 0);
        }

        tagsMap.set(tag, tagsMap.get(tag) + 1);
      }
    }
  }

  return {
    items: items,
    loadedPains: loadedPains,
    loadedSymptoms: loadedSymptoms,
    loadedFoods: loadedFoods,
    loadedBowelMovements: loadedBowelMovements,
    loadedActivities: loadedActivities,
    loadedMedications: loadedMedications,
    datesRange: datesRange,
    tagsMap: tagsMap
  };
};

// For charts and aggregation later we need to ahve convenient way to access entries by pain name and date
// painsMap: {
//   "stomach pain": {
//     "2024-02-22": [{}],
//     "2024-02-29": [{}, {}]
//   }
// }
const addPainItem = (loadedPains, item) => {
  if (!loadedPains.painsMap) {
    loadedPains.painsMap = {};
  }

  // we store it as array of pains, but ensure we always have 1 pain for now
  const painName = item.pains[0];

  if (!loadedPains.painsMap[painName]) {
    loadedPains.painsMap[painName] = {};
  }

  if (!loadedPains.painsMap[painName][item.date]) {
    loadedPains.painsMap[painName][item.date] = [];
  }

  loadedPains.painsMap[painName][item.date].push(item);

  loadedPains.count = loadedPains.count ?? 0;
  loadedPains.count++;
};

// For charts and aggregation later we need to ahve convenient way to access entries by pain name and date
// symptomsMap: {
//   "diziness": {
//     "2024-02-22": [{}],
//     "2024-02-29": [{}, {}]
//   }
// }
const addSymptomItem = (loadedSymptoms, item) => {
  if (!loadedSymptoms.symptomsMap) {
    loadedSymptoms.symptomsMap = {};
  }

  const symptomName = item.symptoms[0];

  if (!loadedSymptoms.symptomsMap[symptomName]) {
    loadedSymptoms.symptomsMap[symptomName] = {};
  }

  if (!loadedSymptoms.symptomsMap[symptomName][item.date]) {
    loadedSymptoms.symptomsMap[symptomName][item.date] = [];
  }

  loadedSymptoms.symptomsMap[symptomName][item.date].push(item);

  loadedSymptoms.count = loadedSymptoms.count ?? 0;
  loadedSymptoms.count++;
};

// For charts and aggregation later we need to ahve convenient way to access entries by pain name and date
// foodsMap: {
//     "2024-02-22": [{}],
//     "2024-02-29": [{}, {}]
// }
const addFoodItem = (loadedFoods, item) => {
  if (!loadedFoods.foodsMap) {
    loadedFoods.foodsMap = {};
  }

  if (!loadedFoods.foodsMap[item.date]) {
    loadedFoods.foodsMap[item.date] = [];
  }

  loadedFoods.foodsMap[item.date].push(item);

  loadedFoods.count = loadedFoods.count ?? 0;
  loadedFoods.count++;
};

// For charts and aggregation later we need to ahve convenient way to access entries by pain name and date
// bowelMovementsMap: {
//     "2024-02-22": [{}],
//     "2024-02-29": [{}, {}]
// }
const addBowelMovementItem = (loadedBowelMovements, item) => {
  if (!loadedBowelMovements.bowelMovementsMap) {
    loadedBowelMovements.bowelMovementsMap = {};
  }

  if (!loadedBowelMovements.bowelMovementsMap[item.date]) {
    loadedBowelMovements.bowelMovementsMap[item.date] = [];
  }

  loadedBowelMovements.bowelMovementsMap[item.date].push(item);

  loadedBowelMovements.count = loadedBowelMovements.count ?? 0;
  loadedBowelMovements.count++;
};

// For charts and aggregation later we need to ahve convenient way to access entries by pain name and date
// activitiesMap: {
//   "boxing": {
//     "2024-02-22": [{}],
//     "2024-02-29": [{}, {}]
//   }
// }
const addActivityItem = (loadedActivities, item) => {
  if (!loadedActivities.activitiesMap) {
    loadedActivities.activitiesMap = {};
  }

  const activityName = item.activities[0];

  if (!loadedActivities.activitiesMap[activityName]) {
    loadedActivities.activitiesMap[activityName] = {};
  }

  if (!loadedActivities.activitiesMap[activityName][item.date]) {
    loadedActivities.activitiesMap[activityName][item.date] = [];
  }

  loadedActivities.activitiesMap[activityName][item.date].push(item);

  loadedActivities.count = loadedActivities.count ?? 0;
  loadedActivities.count++;
};

// For charts and aggregation later we need to ahve convenient way to access entries by pain name and date
// medicationsMap: {
//   "boxing": {
//     "2024-02-22": [{}],
//     "2024-02-29": [{}, {}]
//   }
// }
const addMedicationItem = (loadedMedications, item) => {
  if (!loadedMedications.medicationsMap) {
    loadedMedications.medicationsMap = {};
  }

  const medicationName = item.medications[0];

  if (!loadedMedications.medicationsMap[medicationName]) {
    loadedMedications.medicationsMap[medicationName] = {};
  }

  if (!loadedMedications.medicationsMap[medicationName][item.date]) {
    loadedMedications.medicationsMap[medicationName][item.date] = [];
  }

  loadedMedications.medicationsMap[medicationName][item.date].push(item);

  loadedMedications.count = loadedMedications.count ?? 0;
  loadedMedications.count++;
};