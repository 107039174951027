import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Grid, TextField, Slider } from '@mui/material';
import { toDateFormat, toDateTimeFormat } from 'src/utils/datetime-utils';
import NumberInput from 'src/components/input/NumberInput';
import CommonDiaryEntryForm from '../CommonDiaryEntryForm';
import { generateGuid } from 'src/utils/common-utils';
import SymptomsTagsInput from 'src/components/input/SymptomsTagsInput';
import FavouritesTagsInput from 'src/components/input/FavouritesTagsInput';
import SymptomsFavouritesTutorial from 'src/components/SymptomsFavouritesTutorial';
import { getAllPains } from 'src/services/api';
import { useTranslation } from 'react-i18next';

const allPainTypesEn = [
  'n/a',
  'aching',
  'burning',
  'sharp',
  'dull',
  'spilling',
  'pulsating',
  'spasmodic'
];

const allPainTypesUk = [
  "н/a",
  "болить",
  "пекучий",
  "гострий",
  "тупий",
  "розливається",
  "пульсуючий",
  "спазматичний"
];

const getAllPainTypes = (lang) => {
  if (lang === 'uk') {
    return allPainTypesUk;
  }

  return allPainTypesEn;
}

function PainForm({ title, submitText, onSubmit, defaultValues }) {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const [pains, setPains] = useState(defaultValues?.pains);
  const [painTypes, setPainTypes] = useState(defaultValues?.painTypes);
  const [allPainTypes, setAllPainTypes] = useState([]);
  const [dateTime, setDateTime] = useState(defaultValues?.dateTime);
  const [severity, setSeverity] = useState(defaultValues?.severity);
  const [minutesDuration, setMinutesDuration] = useState(defaultValues?.minutesDuration);
  const [hoursDuration, setHoursDuration] = useState(defaultValues?.hoursDuration);
  const [notes, setNotes] = useState(defaultValues?.notes);
  const [tags, setTags] = useState(defaultValues?.tags);
  const [aiPains, setAiPains] = useState([]);

  useEffect(() => {
    async function load() {
      const currentLanguage = i18n.language;
      const painTypes = getAllPainTypes(currentLanguage);

      setAllPainTypes(painTypes);

      const allPains = await getAllPains(currentLanguage);
      setAiPains(allPains);

      setPains(defaultValues.pains);
      setPainTypes(defaultValues.painTypes);
      setDateTime(defaultValues.dateTime);
      setSeverity(defaultValues.severity);
      setMinutesDuration(defaultValues.minutesDuration);
      setHoursDuration(defaultValues.hoursDuration);
      setNotes(defaultValues.notes);
      setTags(defaultValues.tags);
    }

    load();

  }, [defaultValues, i18n.language]);

  const handleCanceled = () => navigate(-1);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!painTypes || painTypes.length === 0) {
      alert(t('pain.painTypeValidationMessage'));
      return;
    }

    if (!pains || pains.length === 0) {
      alert(t('pain.painValidationMessage'));
      return;
    }

    if (isNaN(hoursDuration)) {
      alert(t('common.correctHoursValidationMessage'));
      return;
    }

    if (isNaN(minutesDuration)) {
      alert(t('common.correctMinutesValidationMessage'));
      return;
    }

    const entry = {
      // it will be overriden later for the edit page, so we will not generate new entry from edit page
      id: generateGuid(),
      entryType: 'pain',
      painTypes: painTypes,
      severity: severity,
      date: toDateFormat(dateTime),
      dateTime: toDateTimeFormat(dateTime),
      pains: pains,
      hoursDuration: hoursDuration,
      minutesDuration: minutesDuration,
      tags: tags,
      notes: notes ?? ''
    };

    onSubmit(entry);
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <CommonDiaryEntryForm
        dateTime={dateTime}
        handleCanceled={handleCanceled}
        setDateTime={setDateTime}
        hoursDuration={hoursDuration}
        setHoursDuration={setHoursDuration}
        minutesDuration={minutesDuration}
        setMinutesDuration={setMinutesDuration}
        title={title}>
        <Grid item xs={12} sm={6}>
          <SymptomsTagsInput
            label={t('pain.painLabel')}
            tags={pains}
            favouriteType="pains"
            updateTags={(values) => setPains(values)}
            limit={1}
            aiItems={aiPains}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FavouritesTagsInput
            label={t('pain.painTypeLabel')}
            tags={painTypes}
            favouriteType="painTypes"
            updateTags={(values) => setPainTypes(values)}
            additionalItems={allPainTypes}
          />
        </Grid>

        <Grid item>
          <SymptomsFavouritesTutorial />
        </Grid>

        <Grid item xs={12}>
          <Typography id="input-slider" gutterBottom>
            {t('severity')}
          </Typography>
          <Slider
            aria-label={t('severity')}
            value={severity}
            onChange={(newValue) => setSeverity(newValue.target.value)}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={0}
            max={10}
          />
        </Grid>

        <Grid item xs={6}>
          <NumberInput
            label={t('hoursDuration')}
            number={hoursDuration}
            updateNumber={setHoursDuration}
          />
        </Grid>

        <Grid item xs={6}>
          <NumberInput
            label={t('minutesDuration')}
            number={minutesDuration}
            updateNumber={setMinutesDuration}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label={t('notesLabel')}
            multiline
            rows={4}
            value={notes}
            onChange={(newValue) => setNotes(newValue.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <FavouritesTagsInput
            label={t('tagsLabel')}
            tags={tags ?? []}
            favouriteType="tags"
            updateTags={setTags}
          />
        </Grid>

        <Grid item xs={12}>
          <Button type="submit" variant="contained">
            {submitText}
          </Button>
          <Button sx={{ ml: 2 }} onClick={handleCanceled}>
            {t('cancel')}
          </Button>
        </Grid>
      </CommonDiaryEntryForm>
    </form>
  );
}

export default PainForm;
