import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

const SymptomsPdfItems = ({ items, theme }) => {
    const { t } = useTranslation();

    Font.register({
        family: "Roboto",
        src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
    });

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            padding: 20,
            backgroundColor: theme.palette.background.default,
        },
        header: {
            fontFamily: "Roboto",
            flexDirection: 'row',
            borderBottomWidth: 1,
            borderBottomColor: theme.palette.black.main,
            paddingBottom: 10,
            marginBottom: 10,
        },
        headerText: {
            width: '33.33%',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 12,
            color: theme.palette.black.main,
            fontFamily: "Roboto"
        },
        row: {
            flexDirection: 'row',
            borderBottomWidth: 1,
            borderBottomColor: theme.palette.black.main,
            paddingBottom: 5,
            marginBottom: 5,
        },
        column: {
            width: '33.33%',
            textAlign: 'center',
            fontSize: 10,
            color: theme.palette.black.main,
            paddingTop: 3,
            paddingBottom: 3,
            fontFamily: "Roboto"
        },
        noItemsText: {
            fontStyle: 'italic',
            fontSize: 12,
            color: theme.palette.black.main,
            marginTop: 10,
            fontFamily: "Roboto"
        },
    });

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.header}>
                    <Text style={styles.headerText}>{t('common.time')}</Text>
                    <Text style={styles.headerText}>{t('symptom.symptom')}</Text>
                    <Text style={styles.headerText}>{t('severity')}</Text>
                    <Text style={styles.headerText}>{t('notes')}</Text>
                </View>
                {items && items.length > 0 ? (
                    items.map((item, index) => (
                        <View key={index} style={styles.row}>
                            <Text style={styles.column}>{item.dateTime}</Text>
                            <Text style={styles.column}>
                                {item.symptoms ? item.symptoms[0] : item.pains[0]}
                            </Text>
                            <Text style={styles.column}>{item.severity}</Text>
                            <Text style={styles.column}>{item.notes}</Text>
                        </View>
                    ))
                ) : (
                    <Text style={styles.noItemsText}>{t('noItemsForThisDate')}</Text>
                )}
            </Page>
        </Document>
    )
}

export default SymptomsPdfItems;