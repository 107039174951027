import { Button, Paper, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ChipList from 'src/components/ChipList';
import { fromDateTimeFormat, toDateTimeFormat } from 'src/utils/datetime-utils';
import { useTranslation } from 'react-i18next';

function CommonDiaryItem({ item, handleDelete, editRoute, children }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Paper elevation={1} sx={{ p: 2, mb: 1 }}>
      <Stack>
        {children}

        {item?.notes && <Typography>{t('notes')}: {item?.notes}</Typography>}

        <ChipList color="strongSuccess" label={t('tags')} bold tags={item?.tags} />

        <Stack
          spacing={{ xs: 1, sm: 1 }}
          justifyContent="flex-end"
          direction="row"
          useFlexGap
          flexWrap="wrap">
          <Button
            onClick={() => {
              navigate(
                `/diary/${editRoute}/${item?.id}?date=${toDateTimeFormat(fromDateTimeFormat(item?.dateTime))}`
              );
            }}
            color="black"
            disabled={item?.mocked}>
            {t('edit')}
          </Button>
          <Button onClick={() => handleDelete(item)} color="black" disabled={item?.mocked}>
            {t('delete')}
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
}

export default CommonDiaryItem;
