import moment, { isDate } from 'moment';
import { useLocation } from 'react-router-dom';

export const UTILS_CONSTANTS = {
  DATE_FORMAT: 'YYYY-MM-DD',
  SHORT_DATE_FORMAT: 'MM-DD',
  DATE_TIME_FORMAT: 'YYYY-MM-DD HH:mm:ss'
};

export const calculateDateRange = (currentDate, daysCount) => {
  const startDate = currentDate;
  const endDate = addDays(currentDate, daysCount - 1);

  return { startDate, endDate };
}

// returns string
export const getDateTimeFromQueryOrNow = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const now = getCurrentDate();

  if (queryParams.size > 0 && queryParams.has('date')) {
    const queryDate = queryParams.get('date');
    let momentDate = null;

    try {
      momentDate = moment(queryDate);
    } catch(e) {
      console.log(`cannot parse ${queryDate}`, e)
    }

    if (momentDate) {
      return toDateTimeFormat(momentDate);
    }
  }

  return toDateTimeFormat(now);
};

export function getCurrentDate() {
  return moment();
}

export function getBeginningOfDayTime() {
  const beginningOfDayTime = moment().startOf('day').add(1, 'second');
  return beginningOfDayTime;
}

export function addDays(date, daysCount) {
  const newDate = moment(date);
  newDate.add(daysCount, 'day');
  return newDate;
}

export function getDatesForRange(startDate, endDate) {
  const dates = [];

  if (startDate >= endDate) {
    return dates;
  }

  while (startDate <= endDate) {
    dates.push(toDateFormat(startDate));

    startDate = addDays(startDate, 1);
  }

  return dates;
}

export function toShortDateFormat(momentDate) {
  return toFormat(momentDate, UTILS_CONSTANTS.SHORT_DATE_FORMAT);
}

export function toDateFormat(momentDate) {
  return toFormat(momentDate, UTILS_CONSTANTS.DATE_FORMAT);
}

export function fromDateFormat(dateString) {
  return fromFormat(dateString, UTILS_CONSTANTS.DATE_FORMAT);
}

export function toDateTimeFormat(momentDate) {
  return toFormat(momentDate, UTILS_CONSTANTS.DATE_TIME_FORMAT);
}

export function fromDateTimeFormat(dateString) {
  return fromFormat(dateString, UTILS_CONSTANTS.DATE_TIME_FORMAT);
}

export function toFormat(momentDate, format) {
  return momentDate.format(format);
}

export function fromFormat(dateString, format) {
  return moment(dateString, format);
}
