import { Box, Chip, Grid, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function InfoPane({ inputAggregated }) {
  const { t } = useTranslation();
  const [aggregated, setAggregated] = useState(inputAggregated);
  const [theMostPainfulPains, setTheMostPainfulPains] = useState([]);
  const [maxPainSeverity, setMaxPainSeverity] = useState(0);

  useEffect(() => {
    setAggregated(inputAggregated);

    if (inputAggregated.items) {
      const pains = inputAggregated.items.filter((i) => i.entryType === 'pain');

      const severities = pains.map((p) => p.severity);
      let maxSev = Math.max(...severities);

      const uniquePains = new Set(
        pains.filter((p) => p.severity === maxSev).map((p) => p.pains[0])
      );

      setTheMostPainfulPains(Array.from(uniquePains));
      setMaxPainSeverity(maxSev === -Infinity ? 0 : maxSev);
    }
  }, [inputAggregated]);

  return (
    <Grid container justifyContent="start" spacing={1}>
      {theMostPainfulPains.length > 0 && (
        <Grid item>
          <Paper sx={{ p: 1, mr: 1, height: '100%' }}>
            <Typography variant="subtitle2">
              <b>{t('pain.maximumPainSeverity')}</b>: {maxPainSeverity}
            </Typography>
            <br></br>
            {theMostPainfulPains.map((part) => (
              <Box key={`box1${part}`}>
                <Chip
                  sx={{ mr: 1 }}
                  color="primary"
                  id={`chip1${part}`}
                  key={`chip1${part}`}
                  variant="outlined"
                  label={part}
                />
              </Box>
            ))}
          </Paper>
        </Grid>
      )}

      {aggregated?.loadedPains?.painsMap && (
        <Grid item>
          <Paper sx={{ p: 1, mr: 1, height: '100%' }}>
            <Typography variant="subtitle2">
              <b>{t('pain.pains')}</b>
            </Typography>
            <br></br>
            {Object.keys(aggregated?.loadedPains?.painsMap).map((part) => (
              <Box key={`box2${part}`}>
                <Chip
                  sx={{ mr: 1 }}
                  color="primary"
                  key={`chip2${part}`}
                  id={part}
                  variant="outlined"
                  label={part}
                />
              </Box>
            ))}
          </Paper>
        </Grid>
      )}

      {aggregated?.loadedSymptoms?.symptomsMap && (
        <Grid item>
          <Paper sx={{ p: 1, mr: 1, height: '100%' }}>
            <Typography variant="subtitle2">
              <b>{t('symptom.symptoms')}</b>
            </Typography>
            <br></br>
            {Object.keys(aggregated?.loadedSymptoms?.symptomsMap).map((symp) => (
              <Box key={`box3${symp}`}>
                <Chip
                  sx={{ mr: 1 }}
                  color="primary"
                  key={`chip3${symp}`}
                  id={symp}
                  variant="outlined"
                  label={symp}
                />
              </Box>
            ))}
          </Paper>
        </Grid>
      )}

      {(aggregated?.loadedBowelMovements?.count > 0 ||
        aggregated?.loadedFoods?.count > 0 ||
        aggregated?.loadedActivities?.count > 0) && (
        <Grid item>
          <Paper sx={{ p: 1, mr: 1, height: '100%' }}>
            {aggregated.loadedBowelMovements?.count > 0 && (
              <Typography variant="subtitle2">
                <b>{t('bowelMovement.bowelMovements')}</b>: {aggregated?.loadedBowelMovements?.count}
              </Typography>
            )}
            {aggregated?.loadedActivities?.count > 0 && (
              <Typography variant="subtitle2">
                <b>{t('activity.activities')}</b>: {aggregated?.loadedActivities?.count}
              </Typography>
            )}
            {aggregated?.loadedFoods?.count > 0 && (
              <Typography variant="subtitle2">
                <b>{t('food.foods')}</b>: {aggregated?.loadedFoods?.count}
              </Typography>
            )}
          </Paper>
        </Grid>
      )}

      {aggregated?.tagsMap?.size > 0 && (
        <Grid item>
          <Paper sx={{ p: 1, mr: 1, height: '100%' }}>
            <Typography variant="subtitle2">
              <b>{t('tags')}</b>
            </Typography>

            <br></br>

            {Array.from(aggregated?.tagsMap?.keys()).map((k) => {
              return (
                <Chip
                  sx={{ mr: 1 }}
                  color="primary"
                  id={k}
                  key={k}
                  variant="outlined"
                  label={`${k}:${aggregated?.tagsMap.get(k)}`}
                />
              );
            })}
          </Paper>
        </Grid>
      )}
    </Grid>
  );
}

export default InfoPane;
