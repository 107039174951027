import { useEffect, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, Select } from '@mui/material';
import CommonChart from './CommonChart';

function PainsChart({ inputAggregated }) {
  const { t } = useTranslation();

  const [painCountName, setPainCountName] = useState('');
  const [painSeverityName, setPainSeverityName] = useState('');
  const [chartData, setChartData] = useState([]);
  const [painNames, setPainNames] = useState([]);
  const [chartPainName, setChartPainName] = useState('');

  useEffect(() => {
    const painsMap = inputAggregated?.loadedPains?.painsMap;
    const datesRange = inputAggregated?.datesRange;

    const names = painsMap ? Object.keys(painsMap) : []
    let painName;

    if (names.includes(chartPainName)) {
      painName = chartPainName;
    }

    if (!chartPainName) {
      painName = names[0] ?? '';
    }

    if (!painName || !painsMap || !datesRange) {
      setPainNames([]);
      setChartPainName('');
      setChartData([]);
      setPainCountName('');
      setPainSeverityName('')
      return;
    }

    const painCountChartObj = {};
    const painSeverityChartObj = {};

    for (let date of datesRange) {
      painCountChartObj[painName] ??= [];
      painSeverityChartObj[painName] ??= [];

      const painsOnDate = painsMap[painName][date];

      let painCount = 0;
      let painSeverity = 0;

      if (painsOnDate) {
        painCount = painsOnDate.length;
        painSeverity = Math.max(...painsOnDate.map(obj => obj.severity));
      }

      painCountChartObj[painName].push(painCount);
      painSeverityChartObj[painName].push(painSeverity);
    }

    const chartData = [];
    const painCountName = `${painName} ${t('count')}`;
    const painSeverityName = `${painName} ${t('severity')}`;

    for (let i = 0; i < datesRange.length; i++) {
      let item = {
        name: datesRange[i]
      };

      item[painCountName] = painCountChartObj[painName][i];
      item[painSeverityName] = painSeverityChartObj[painName][i];

      chartData.push(item);
    }
    // {
    //     "obj": {
    //         "throat count": [0, 0, 0, 0, 0, 0, 0, 1],
    //         "throat severity": [0, 0, 0, 0, 0, 0, 0, 5],
    //     },
    //     "datesRange": ["2023-07-27", "2023-07-28", "2023-07-29", "2023-07-30", "2023-07-31", "2023-08-01", "2023-08-02", "2023-08-03"]
    // }

    setPainNames(names);
    setChartPainName(painName);
    setPainCountName(painCountName);
    setPainSeverityName(painSeverityName)
    setChartData(chartData);
  }, [inputAggregated, chartPainName]);

  return (
    <>
      {painNames && painNames.length > 0 && (
        <Select
          labelId="pain-selector"
          id="pain-selector"
          value={chartPainName}
          onChange={(e) => { setChartPainName(e.target.value) }}>
          {painNames.map(p => <MenuItem key={p} value={p}>{p}</MenuItem>)}
        </Select>
      )}
      <Box sx={{ml: -7}}>
        <CommonChart
          chartName={t('painChart')}
          chartData={chartData}
          lines={[{ name: painSeverityName, color: "#5280EB" }, { name: painCountName, color: "#82ca9d" }]} />
      </Box>
    </>
  );
}

export default PainsChart;
